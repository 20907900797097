<div [formGroup]="form" *transloco="let t">
  <mat-form-field appearance="outline">
    <mat-label>{{ t('sessiondetails.label.date') }}</mat-label>

    <input matInput [matDatepicker]="datePicker"
           placeholder="{{ t('sessiondetails.label.chooseadate') }}" formControlName="date" [min]="minDate" [max]="maxDate" required>
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker [touchUi]="isTouchUi"></mat-datepicker>
    <mat-hint class="mat-hint" align="end">MM/DD/YYYY</mat-hint>

    <mat-error *ngIf="dateControl?.hasError('required') && !dateControl?.hasError('matDatepickerParse')">
      {{ t('sessiondetails.error.date_required') }}
    </mat-error>
    <mat-error *ngIf="dateControl?.hasError('matDatepickerParse')">
      {{ t('sessiondetails.error.date_invalid_format') }}
    </mat-error>
    <mat-error *ngIf="dateControl?.hasError('matDatepickerMin')">
      {{ t('sessiondetails.error.session_start_mindays_range') }}
    </mat-error>
    <mat-error *ngIf="dateControl?.hasError('matDatepickerMax')">
      {{ t('sessiondetails.error.session_start_maxdays_range', { maxDays: maxDays }) }}
    </mat-error>
  </mat-form-field>
</div>

