import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { faBan } from '@fortawesome/pro-regular-svg-icons';
import { faEllipsis, faLaptop, faFilePen, faCheckCircle, faMessageLines } from '@fortawesome/pro-solid-svg-icons';
import { EmailService } from 'src/app/services/email.service';
import { CancelSessionService } from 'src/app/services/cancel-session.service';
import { SessionStatusUpdateService } from 'src/app/services/session-status-update.service';
import { SessionValidationService } from 'src/app/services/session-validation.service';
import { AllowedRoles, ISessionScheduleList } from '../../../models/session';
import { StartSessionService } from 'src/app/services/start-session.service';
import { EditViewSidesheetService } from '../../../services/edit-view-sidesheet.service';
import { TranslocoService } from '@jsverse/transloco';
import { UserDetailService } from '@certiport/login-library';
import { MatSort } from '@angular/material/sort';
import { PaginatorIntlService } from '../../../services/paginator-intl.service';
import { map, Observable, Subscription, switchMap } from 'rxjs';
import { CtpDatePipe } from 'certiport-layout-library'
import { InvokeSessionDetails, SessionInvokeType, SharedStateService } from 'src/app/services/shared-state.service';
import { SMSService } from 'src/app/services/sms.service';

@Component({
  selector: 'app-session-schedule-table',
  templateUrl: './session-schedule-table.component.html',
  styleUrls: ['./session-schedule-table.component.scss']
})
export class SessionScheduleTableComponent implements OnInit, OnChanges {
  @Input() sessionScheduleData: ISessionScheduleList[] = [];
  @Input() hideOptions: boolean = false;
  @Input() showTitle: boolean = true;
  @Input() searchValue: string = "";

  @Input() noDataText: string = "";
  @ViewChild(MatSort) public sort!: MatSort;

  activeSessionScheduleData: ISessionScheduleList[] = [];
  proctorTableColumns: string[] = ['sessionName', 'sessionStatus', 'startDateTimeDisplay', 'numberOfCandidates', 'numberOfExams', 'option'];
  orgAdminTableColumns: string[] = ['sessionName', 'sessionStatus', 'startDateTimeDisplay', 'creator', 'proctor', 'option'];
  displayedColumns: string[] = ['sessionName', 'sessionStatus', 'startDateTimeDisplay', 'option'];
  pageSize = 10;
  pageSizeOptions = [5, 10];
  public matDataSource = new MatTableDataSource<ISessionScheduleList>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  private readonly orgAdminOrOrgMemberRoles: number[] = [AllowedRoles.orgadmin, AllowedRoles.orgmember];
  userRoles: number[] = [];
  @Output() onResendConfirmationEmail = new EventEmitter();
  public readonly icons = {
    faEllipsis,
    faLaptop,
    faFilePen,
    faCheckCircle,
    faMessageLines,
    faBan
  }

  public selection = new SelectionModel<ISessionScheduleList>(true, []);

  constructor(private sessionValidationService: SessionValidationService,
    private sessionStatusUpdateService: SessionStatusUpdateService, private emailService: EmailService,
    private cancelSessionService: CancelSessionService,
    private startSessionService: StartSessionService,
    private editViewSidesheetService: EditViewSidesheetService,
    private userDetailService: UserDetailService,
    private t: TranslocoService,
    private paginatorIntlService: PaginatorIntlService,
    private sharedStateService: SharedStateService,
    private smsService: SMSService) {

    t.langChanges$.subscribe(lang => {
      this.loadPaginator();
    });

  }

  ngOnInit(): void {
    this.getSessionScheduleTable();
    this.matDataSource.sortingDataAccessor = (data: any, sortHeaderId: string) => {
      //To properly unit test the result of the data accessor, we need to generate the appropriate sort event.
      // The table is not currently a mat-table, and I have not found a way to properly generate the event for a cdk-table.
      // This should be revisited when and if Topaz migrates tables to use mat-table instead.
      return this.getSortingDataAccessor(data, sortHeaderId);
    }
    this.handleSharedStateService();
  }

  handleSharedStateService(): void {

    const invokeSessionData = this.sharedStateService.getInvokeSessionData();

    if (!invokeSessionData?.invokeSessionData) return;

    if(!invokeSessionData?.sessionCode){
      this.performSessionAction(invokeSessionData, invokeSessionData.sessionId);
      return;
    }

    this.smsService.getSession(invokeSessionData.sessionCode).pipe().subscribe(sessionInfo => {
      this.performSessionAction(invokeSessionData, sessionInfo.sessionId);
    });
  }

  private performSessionAction(invokeSessionData: InvokeSessionDetails, sessionId: number) {

    if (invokeSessionData.invokeType === SessionInvokeType.ViewSession)
      this.viewSession(sessionId);
    else if (invokeSessionData.invokeType === SessionInvokeType.EditSession)
      this.editSession(sessionId);
    else if (invokeSessionData.invokeType === SessionInvokeType.ResendConfirmationEmail)
      this.resendConfirmationEmail(sessionId);
    else if (invokeSessionData.invokeType === SessionInvokeType.StartSession)
      this.startSession(sessionId);

    this.sharedStateService.clearSessionData();
  }

  getSortingDataAccessor(data: any, sortHeaderId: string): any {
    if (typeof(data[sortHeaderId]) === 'string') {
      return data[sortHeaderId].toLowerCase();
    }
    return data[sortHeaderId];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.matDataSource.filter = this.searchValue || '';
    if (changes['sessionScheduleData']?.currentValue != changes['sessionScheduleData']?.previousValue) {
      this.matDataSource.data = this.sessionScheduleData;
      this.loadPaginator();
      
    }
  }

  private loadPaginator() {
    setTimeout(() => {
      this.paginatorIntlService.nextPageLabel = this.t.translate('examTablePaginator.tooltip.nextPage');
      this.paginatorIntlService.previousPageLabel = this.t.translate('examTablePaginator.tooltip.previousPage');
      this.paginatorIntlService.itemsPerPageLabel = this.t.translate('examTablePaginator.rowsPerPage');

      if (this.paginator != undefined) {
        this.paginator._intl = this.paginatorIntlService;
      }
      this.matDataSource.sort = this.sort;
      this.matDataSource.paginator = this.paginator;
    }, 500);
  }
  private getSessionScheduleTable() {
    this.userRoles = this.userDetailService.getUserDetail().userRoles;
    const isOrgAdminOrOrgMember = this.userRoles.some(r => this.orgAdminOrOrgMemberRoles.includes(r));
    if (isOrgAdminOrOrgMember) {
      this.displayedColumns = this.orgAdminTableColumns;
    }
    else {
      this.displayedColumns = this.proctorTableColumns;
    }
    this.setupFilter();
    this.getSessionSchedule();
  }

  setupFilter() {
    this.matDataSource.filterPredicate = (data: ISessionScheduleList, filter: string): boolean => {
      return data.sessionName.toLowerCase().includes(filter.toLowerCase());
    }
  }

  getSessionSchedule() {
    this.matDataSource.data = this.sessionScheduleData;
  }

  onPageChanged(event: PageEvent) {
    const firstPos = event.pageIndex * event.pageSize;
    const lastPos = firstPos + event.pageSize;
    this.matDataSource.data = this.sessionScheduleData.slice(firstPos, lastPos);
  }

  viewSession(sessionId: number) {
    this.editViewSidesheetService.openViewSessionSidesheet(sessionId);
  }

  editSession(sessionId: number) {
    this.editViewSidesheetService.openEditSessionSidesheet(sessionId);
  }
  startSession(sessionId: number) {
    let subscription: Subscription = this.startSessionService.startSession(sessionId).subscribe(() => {
      subscription.unsubscribe();
    });
  }

  resendConfirmationEmail(sessionId: number) {
    this.emailService.resendConfirmatioEmail(sessionId);
  }

  cancelSession(sessionId: number, sessionName: string, sessionstartDate: Date) {
    this.cancelSessionService.cancelSessionConfirmation({ sessionId: sessionId, sessionName: sessionName, startDateTime: sessionstartDate });
  }
  confirmSession(sessionId: number, sessionName: string, sessionstartDate: Date) {
    this.sessionStatusUpdateService.confirmSession({ sessionId: sessionId, sessionName: sessionName, startDateTime: sessionstartDate });
  }

  isNoSessionExist() {
    return !this.matDataSource.data.length || !this.matDataSource.filteredData.length;
  }

  isSessionExist() {
    return this.matDataSource.data.length && this.matDataSource.filteredData.length;
  }

  resumeSession(sessionId: number) {
    this.startSessionService.openExamSessionWindow(sessionId)
  }


}
