<div class="topaz-page-content" role="main">
  <div *transloco="let t;">
    <div class="section-title">
      <div class="desktop">
        <h1 autofocus>{{ t('connectioncheck.header') }}</h1>
      </div>
    </div>
    <mat-card class="example-card" appearance="outlined"
              id="threeColumnCard">
      <mat-card-title>
        <h2 topazTitle="4">{{ t('connectioncheck.instructionsHeader') }}</h2>
      </mat-card-title>
      <mat-card-content class="grid-layout three-column-wrapper">
        <span class="center-items">
          <img src="assets/images/connectionCheck.svg" />
        </span>
        <span [innerHTML]="t('connectioncheck.instructionsBody')"><!--This is how we put in text when html is in the translation-->
        </span>
          <div class="right-pane">
            <div class="row">
              <div class="basic-checkboxes">
                <mat-checkbox id="basicCheck" [(ngModel)]="isChecked">{{ t('connectioncheck.checkboxText') }}</mat-checkbox>
              </div>
            </div>
            <button mat-flat-button color="primary" class="full-width" [disabled]="!isChecked">{{ t('connectioncheck.runCheck') }}</button>
          </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
