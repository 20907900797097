import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ISession, IUpdateSessionStatus } from '../models/session';
import { ConfigService } from './config-service';

@Injectable({
  providedIn: 'root'
})
export class SMSService {

  isSMSSessionConfirmPage$ = new Subject<boolean>;
  constructor(private httpClient: HttpClient, private configService: ConfigService) { }

  getSession(sessionCode: string): Observable<ISession> {
    const url = `${this.configService.schedulingServiceURL}/sms/session?sessionCode=${sessionCode}`;
    return this.httpClient.get<ISession>(url);
  }

  updateSessionStatus(updateSessionStatus: IUpdateSessionStatus): Observable<any> {
    const url = `${this.configService.schedulingServiceURL}/sms/session/status`;
    return this.httpClient.patch<any>(url, updateSessionStatus);
  }
}

