import { Component, OnInit } from '@angular/core';
import { SettingsCacheService } from 'src/app/services/settings-cache.service';
import { catchError, EMPTY, filter, map, Observable } from 'rxjs';
import { SchedulingErrorCodeService } from 'src/app/services/scheduling-error-code.service';

//I'm not sure exactly why we need this here, but the app won't compile without it.
import { CommonLibModule, CommunicationPreferenceRequestService, CommunicationPreferences_GetDto, CommunicationPreferences_Update_Dto } from '@certiport/common';
import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { CountriesService } from 'src/app/services/countries.service';
import { ToastManagerService } from 'src/app/services/toast-manager.service';
import { DeviceTypeService } from 'src/app/services/device-type.service';
import { CanComponentDeactivate } from 'src/app/guards/can-deactivate.guard';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { IConfirmDialogData } from '@cbb/models';
import { MatDialog } from '@angular/material/dialog';
import { CommonmethodsService } from '../../create-session/create-session-services/commonmethods.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LoginStorageTokens } from '@certiport/login-library';
import { SidenavService } from '../../../services/sidenav.service';


@Component({
  selector: 'app-preferences-page',
  standalone: true,
  imports: [CommonLibModule],
  templateUrl: './preferences-page.component.html',
  styleUrl: './preferences-page.component.scss',
  providers: [{
    provide: TRANSLOCO_SCOPE,
    useValue: 'common'
  }]
})

export class PreferencesPageComponent implements OnInit, CanComponentDeactivate {

  settingsLoaded = false;
  loggedInUserId!: number;
  inEditMode: boolean = false;
  firstConfirmationTextMinutes = 0;
  secondConfirmationTextMinutes = 0;
  communicationPreferences: CommunicationPreferences_GetDto | null = null;
  smsCountries: string[] | undefined = undefined;

  constructor(
    private settingsCache: SettingsCacheService,
    private communicationService: CommunicationPreferenceRequestService,
    private schedulingErrorCodeService: SchedulingErrorCodeService,
    private countriesService: CountriesService,
    private sidenavService: SidenavService,
    private toastManagerService: ToastManagerService,
    private deviceType: DeviceTypeService,
    private transloco: TranslocoService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private commonmethodsService: CommonmethodsService,
  ) {
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.isLoggedIn() && this.loggedInUserId > 0 ) {
      if (this.communicationService.hasUnsavedChanges())
        return this.showUnsavedChangesDialog();
    }

    return true;
  }

  private isLoggedIn(): boolean {
    if (this.localStorageService.getItem(LoginStorageTokens.Refresh)) {
      return true;
    }
    return false;
  }

  private showUnsavedChangesDialog() {
    const dialogheader = this.transloco.translate("common.unsavedChanges.header");
    var dialogContents: string[] = [];
    dialogContents.push(this.transloco.translate("common.unsavedChanges.dataWillBeLost"));
    dialogContents.push(this.transloco.translate("common.unsavedChanges.doYouWantToProceed"));

    var confirmDialogData = {
      header: dialogheader,
      contents: dialogContents,
      buttonType: "warn",
      showCloseButton: true,
      isCancelDialog: false

    } as IConfirmDialogData;

    return this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialogData
    }).afterClosed().pipe(filter(value => value?.toLowerCase() === 'yes'));
  }

  ngOnInit(): void {

    this.loggedInUserId = this.commonmethodsService.getLoggedInUserId();

    this.configureSidenav();

    this.communicationService.preferencesObservable.subscribe(x => {
      this.communicationPreferences = x;
    });

    this.communicationService.errorObservable.subscribe(x => {
      if (x != null) {
        this.schedulingErrorCodeService.showErrorCodeToast(x?.errorCode, x?.traceId);
      }
    });

    this.settingsCache.load().pipe(
      map(x => {
        this.firstConfirmationTextMinutes = this.settingsCache.maxConfirmSessionMinutes;
        this.secondConfirmationTextMinutes = this.settingsCache.secondSessionConfirmationLeadTimeMinutes;
        this.settingsLoaded = true;

        this.communicationService.getPreferencesEx();
      }),
      catchError(x => {
        if (x != null) {
          this.schedulingErrorCodeService.showErrorCodeToast(x?.error?.errorCode, x?.error?.traceId);
        }
        return EMPTY;
      })
    ).subscribe();

    this.countriesService.countries.subscribe(x => {
      this.smsCountries = x?.map(y => y.iso2CountryCode);;
    });

    this.countriesService.loadCountries();
  }

  private configureSidenav() {
    this.sidenavService.sidenavSubject
      .next({
        heading: 'common.pref.preferences',
        buttonName: '',
        showArrow: false
      });

    this.sidenavService
      .arrowClickSubject
      .subscribe()
  }

  handleClickedSave(u: CommunicationPreferences_Update_Dto): void {
    this.communicationService.updateCommunicationPreferences(u).pipe(
      map(x => {
        this.cacheValues(x);
        this.inEditMode = false;
        this.communicationService.currentPreferences = x;
        this.communicationService.markFormAsPristine();
        this.showSucessSaveToast();
      }),
      catchError(x => {
        if (x?.error) {
          this.schedulingErrorCodeService.showErrorCodeToast(x?.error?.errorCode, x?.error?.traceId);
        }
        else{
          this.schedulingErrorCodeService.showGenericErrorToast();
        }

        return EMPTY;
      })
    ).subscribe();

  }

  cacheValues(communicationPreferences: CommunicationPreferences_GetDto) {
    this.communicationPreferences = communicationPreferences;
  }

  handleClickedEdit(): void {
    this.inEditMode = true;
  }

  handleClickedCancel(): void {
    this.inEditMode = false;
    this.communicationPreferences = this.deepCopy(this.communicationPreferences);
  }

  private deepCopy<T>(x: T): T {
    return JSON.parse(JSON.stringify(x));
  }

  private showSucessSaveToast() {

    let saveSuccessMsg = this.transloco.translate("pref.saveSuccessDesktop");
    if (this.deviceType.isMobileDeviceWidth()) {
      saveSuccessMsg = this.transloco.translate("pref.saveSuccessMobile");
    }

    this.toastManagerService.showTemporaryToast(saveSuccessMsg, '', 'success');
  }

}

