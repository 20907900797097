import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { SettingsService } from './settings.service';
import { ISettingsInfo } from '../models/settingsInfo';
import { HttpStatusCode } from '@angular/common/http';
import { SchedulingErrorCodeService } from './scheduling-error-code.service';
import { UserDetailService } from '@certiport/login-library';

export interface ISettingsCache {
  get maxStartSessionLateTime(): number;
  get minStartSessionLeadTime(): number;
  get maxJoinSessionLateTime(): number;
  get maxSessionWindowInMinutes(): number;
  get minConfirmationLeadTime(): number;
  get maxConfirmSessionMinutes(): number;
  get minCancellationLeadTime(): number;
  get maxEditTime(): number;
  get proctorSurveyUrl(): string;
  get maxScheduleLeadTime(): number;
  get minScheduleLeadTime(): number;
  get maxExamsPerSession(): number;
  get minCandidateCount(): number;
  get maxCandidateCount(): number;
  get maxExamMinutes(): number;
  get minUpdateLeadTime(): number;
  get minAssociateCandidateLeadTime(): number;
  get secondSessionConfirmationLeadTimeMinutes(): number;
  get smsFeatureEnabled(): boolean;
  get iesFeatureEnabled(): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SettingsCacheService implements ISettingsCache {
  private _loadedInternal: boolean = false;
  public userLoggedIn = false;
  constructor(
    private settingsService: SettingsService,
    private schedulingErrorCodeService: SchedulingErrorCodeService,
    private userDetailService: UserDetailService
  ) { }

  load(): Observable<boolean> {
    if (this._loadedInternal) {
      return of(true);
    }
    
    return this.settingsService.getSettings().pipe(
      map((settings) => {
        this.extractSettings(settings);
        this._loadedInternal = true;
        this.loaded.next(true);
        if (this.userDetailService.getUserDetail() && this.userDetailService.getUserDetail().userName.trim().length > 0) {
          this.userLoggedIn = true;
        }
        return true;
      }),
      catchError(error => {
        if (error?.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode)
          this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId)
        else
          this.schedulingErrorCodeService.showGenericErrorToast();
        throw error;
      })
    );
  }

  loadOnLogin(): Observable<boolean> {
    return this.settingsService.getSettings().pipe(
      map((settings) => {
        this.extractSettings(settings);
        this._loadedInternal = true;
        this.loaded.next(true);
        this.userLoggedIn = true;
        return true;
      }),
      catchError(error => {
        if (error?.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode)
          this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId)
        else
          this.schedulingErrorCodeService.showGenericErrorToast();
        throw error;
      })
    );
  }

  _loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get loaded(): BehaviorSubject<boolean> {
    return this._loaded;
  };

  private extractSettings(settings: ISettingsInfo[]): void {
    this._maxStartSessionLateTime = this.getSettingValueAsNumber('maxStartSessionLateTime', settings);
    this._minStartSessionLeadTime = this.getSettingValueAsNumber('minStartSessionLeadTime', settings);
    this._maxJoinSessionLateTime = this.getSettingValueAsNumber('maxJoinSessionLateTime', settings);
    this._maxSessionWindowInMinutes = this.getSettingValueAsNumber('maxSessionWindowInMinutes', settings);
    this._minConfirmationLeadTime = this.getSettingValueAsNumber('minConfirmationLeadTime', settings);
    this._maxConfirmSessionMinutes = this.getSettingValueAsNumber('maxConfirmSessionMinutes', settings);

    this._minCancellationLeadTime = this.getSettingValueAsNumber('minCancellationLeadTime', settings);
    this._maxEditTime = this.getSettingValueAsNumber('maxEditTime', settings);

    this._proctorSurveyUrl = this.getSettingValueAsString('proctorSurveyUrl', settings);

    this._maxScheduleLeadTime = this.getSettingValueAsNumber('maxScheduleLeadTime', settings);
    this._minScheduleLeadTime = this.getSettingValueAsNumber('minScheduleLeadTime', settings);
    this._maxExamsPerSession = this.getSettingValueAsNumber('maxExamsPerSession', settings);
    this._minCandidateCount = this.getSettingValueAsNumber('minCandidateCount', settings);
    this._maxCandidateCount = this.getSettingValueAsNumber('maxCandidateCount', settings);
    this._maxExamMinutes = this.getSettingValueAsNumber('maxExamMinutes', settings);
    this._minUpdateLeadTime = this.getSettingValueAsNumber('minUpdateLeadTime', settings);
    this._minAssociateCandidateLeadTime = this.getSettingValueAsNumber('minAssociateCandidateLeadTime', settings);
    this._secondSessionConfirmationLeadTimeMinutes = this.getSettingValueAsNumber('secondSessionConfirmationLeadTimeMinutes', settings);
    this._smsFeatureEnabled = this.getSettingValueAsBoolean('SMSFeatureEnabled', settings);
    this._iesFeatureEnabled = this.getSettingValueAsBoolean('IESFeatureEnabled', settings);
  }

  private getSettingValueAsNumber(settingName: string, settings: ISettingsInfo[]): number {
    return parseInt(settings.filter(x => x.settingName == settingName).map(s => s.settingValue).toString());
  }

  getSettingValueAsString(settingName: string, settings: ISettingsInfo[]): string {
    return settings.filter(x => x.settingName == settingName).map(s => s.settingValue).toString();
  }

  private getSettingValueAsBoolean(settingName: string, settings: ISettingsInfo[]): boolean {
    const setting = settings.find(x => x.settingName == settingName)?.settingValue;

    return setting === 'true' ? true : false;
  }

  private _maxStartSessionLateTime: number = 0;
  public get maxStartSessionLateTime(): number {
    this.checkLoaded();
    return this._maxStartSessionLateTime;
  }

  private _minStartSessionLeadTime: number = 0;
  public get minStartSessionLeadTime(): number {
    this.checkLoaded();
    return this._minStartSessionLeadTime;
  }

  private _maxJoinSessionLateTime: number = 0;
  public get maxJoinSessionLateTime(): number {
    this.checkLoaded();
    return this._maxJoinSessionLateTime;
  };

  private _maxSessionWindowInMinutes: number = 0;
  public get maxSessionWindowInMinutes(): number {
    this.checkLoaded();
    return this._maxSessionWindowInMinutes;
  }

  private _minConfirmationLeadTime: number = 0;
  public get minConfirmationLeadTime(): number {
    this.checkLoaded();
    return this._minConfirmationLeadTime;
  }

  private _maxConfirmSessionMinutes: number = 0;
  public get maxConfirmSessionMinutes(): number {
    this.checkLoaded();
    return this._maxConfirmSessionMinutes;
  }

  private _minCancellationLeadTime: number = 0;
  public get minCancellationLeadTime(): number {
    this.checkLoaded();
    return this._minCancellationLeadTime;
  }

  private _maxEditTime: number = 0;
  public get maxEditTime(): number {
    this.checkLoaded();
    return this._maxEditTime;
  }

  private _proctorSurveyUrl: string = '';
  public get proctorSurveyUrl(): string {
    this.checkLoaded();
    return this._proctorSurveyUrl;
  }

  private _maxScheduleLeadTime: number = 0;
  public get maxScheduleLeadTime(): number {
    this.checkLoaded();
    return this._maxScheduleLeadTime;
  }

  private _minScheduleLeadTime: number = 0;
  public get minScheduleLeadTime(): number {
    this.checkLoaded();
    return this._minScheduleLeadTime;
  }

  private _maxExamsPerSession: number = 0;
  public get maxExamsPerSession(): number {
    this.checkLoaded();
    return this._maxExamsPerSession;
  }

  private _minCandidateCount: number = 0;
  public get minCandidateCount(): number {
    this.checkLoaded();
    return this._minCandidateCount;
  }

  private _maxCandidateCount: number = 0;
  public get maxCandidateCount(): number {
    this.checkLoaded();
    return this._maxCandidateCount;
  }

  private _maxExamMinutes: number = 0;
  public get maxExamMinutes(): number {
    this.checkLoaded();
    return this._maxExamMinutes;
  }

  private _minUpdateLeadTime: number = 0;
  public get minUpdateLeadTime(): number {
    this.checkLoaded();
    return this._minUpdateLeadTime;
  }

  private _minAssociateCandidateLeadTime: number = 0;
  public get minAssociateCandidateLeadTime(): number {
    this.checkLoaded();
    return this._minAssociateCandidateLeadTime;
  }

  private _secondSessionConfirmationLeadTimeMinutes: number = 0;
  public get secondSessionConfirmationLeadTimeMinutes(): number {
    this.checkLoaded();
    return this._secondSessionConfirmationLeadTimeMinutes;
  }

  private _smsFeatureEnabled: boolean = false;
  public get smsFeatureEnabled(): boolean {
    this.checkLoaded();
    return this._smsFeatureEnabled;
  }

  private checkLoaded() {
    if (!this._loadedInternal)
      throw Error("Settings are not loaded.");
  }
  private _smsPreferenceEnabled: boolean = false;
  public get smsPreferenceEnabled(): boolean {
    this.checkLoaded();
    return this._smsPreferenceEnabled;
  }

  private _iesFeatureEnabled: boolean = false;
  public get iesFeatureEnabled(): boolean {
    this.checkLoaded();
    return this._iesFeatureEnabled;
  }
}
