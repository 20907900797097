import { CreateSessionComponent } from './components/create-session/create-session.component';
//import { TimePipe } from './pipes/time.pipe';
// modules
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CdkTableModule } from '@angular/cdk/table';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslocoRootModule } from './transloco-root.module';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDividerModule } from '@angular/material/divider';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
  MAT_PAGINATOR_DEFAULT_OPTIONS,
} from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import {
  MatFormFieldModule,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';

import { CertiportLoginLibraryModule } from '@certiport/login-library';
import { CertiportLayoutLibraryModule } from 'certiport-layout-library';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {
  TopazCapsuleModule,
  TopazGlobalBarModule,
  TopazLoadingModule,
  TopazPaginatorIntl,
  TopazTimepickerModule,
} from '@pearsonvue/topaz-angular-ui';
import {
  TopazBannerModule,
  TopazFooterModule,
  TopazToastModule,
} from '@pearsonvue/topaz-angular-ui';
import { TopazSearchModule } from '@pearsonvue/topaz-angular-ui';
import {
  topazDefaultDialogOptions,
  TopazSideSheetModule,
} from '@pearsonvue/topaz-angular-ui';

// components
import { AppComponent } from './app.component';

import { DashboardComponent } from './components/dashboard/dashboard.component';

import { CreateSessionWarningDialogComponent } from './components/create-session/create-session-warning-dialog/create-session-warning-dialog.component';

import { environment } from '../environments/environment';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { enUS } from 'date-fns/locale';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoginComponent } from './components/login/login.component';
import { ToastComponent } from './toast/toast.component';
import { SessionDetailViewComponent } from './components/view-session-layout/session-detail-view/session-detail-view.component';
import { EditSessionComponent } from './components/edit-session-layout/edit-session/edit-session.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { EditSessionExamsComponent } from './components/edit-session-layout/edit-session-exams/edit-session-exams.component';
import { ToastCustomComponent } from './components/toast-custom/toast-custom.component';
import { ViewSessionExamsComponent } from './components/view-session-layout/view-session-exams/view-session-exams.component';
import { SessionScheduleTableComponent } from './components/dashboard/session-schedule-table/session-schedule-table.component';
import { NextSessionComponent } from './components/dashboard/next-session/next-session.component';

import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { SessionConfirmationComponent } from './components/session-confirmation-layout/session-confirmation/session-confirmation.component';
import { EmailConfirmationDialogComponent } from './components/session-confirmation-layout/email-confirmation-dialog/email-confirmation-dialog.component';
import { ViewSessionsComponent } from './components/view-sessions/view-sessions.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { AuthInterceptor } from './guards/auth/auth.interceptor';
import { BannerCustomComponent } from './components/banner-custom/banner-custom.component';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { BannerEditSessionComponent } from './components/edit-session-layout/banner-edit-session/banner-edit-session.component';
import { InsufficientPaymentToastComponent } from './components/create-session/insufficient-payment-toast/insufficient-payment-toast.component';
import { TimeSpanPipe } from './pipes/time-span.pipe';
import { PlatformLocation, APP_BASE_HREF, DatePipe } from '@angular/common';

function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}
import { ExamsListComponent } from './components/create-session/exams-list/exams-list.component';
import { ExamDateTimeRegionComponent } from './components/create-session/exam-date-time-region/exam-date-time-region.component';
import { TimeSelectionComponent } from './components/create-session/exam-date-time-region/time-selection/time-selection.component';
import { DateSelectionComponent } from './components/create-session/exam-date-time-region/date-selection/date-selection.component';
import { TimeZoneSelectionComponent } from './components/create-session/exam-date-time-region/time-zone-selection/time-zone-selection.component';
import { SessionreviewComponent } from './components/create-session/sessionreview/sessionreview.component';
import { LanguageComponent } from './components/create-session/language/language.component';
import { PreventBlacklistCharactersDirective } from './directives/prevent-blacklist-characters.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PaginatorIntlService } from './services/paginator-intl.service';
import { TOPAZ_BLOCK_SCROLL_STRATEGY } from '@pearsonvue/topaz-angular-ui';
import { MAT_DIALOG_SCROLL_STRATEGY } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfigService } from './services/config-service';
import { ExpiredSiteLicenseToastComponent } from './components/create-session/expired-site-license-toast/expired-site-license-toast.component';
import { ExpiringSlaProgramsBannerComponent } from './components/session-confirmation-layout/expiring-sla-programs-banner/expiring-sla-programs-banner.component';
import { PaymentDefinitionComponent } from './components/create-session/payment-definition/payment-definition.component';
import { ConfirmSessionComponent } from './components/mobile/confirm-session/confirm-session.component';

import { CommonLibModule } from '@certiport/common';
import { PreferencesPageComponent } from './components/preferences/preferences-page/preferences-page.component';
const appInitializerFn = (configService: ConfigService) => () => configService.setConfig();

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    EditSessionComponent,
    ConfirmDialogComponent,
    ToastComponent,
    SessionDetailViewComponent,
    EditSessionExamsComponent,
    CreateSessionWarningDialogComponent,
    ToastCustomComponent,
    ViewSessionExamsComponent,
    SessionConfirmationComponent,
    EmailConfirmationDialogComponent,
    SessionScheduleTableComponent,
    ViewSessionsComponent,
    NextSessionComponent,
    BannerCustomComponent,
    NumberOnlyDirective,
    //TimePipe,
    BannerEditSessionComponent,
    CreateSessionComponent,
    InsufficientPaymentToastComponent,
    TimeSpanPipe,
    ExamsListComponent,
    ExamDateTimeRegionComponent,
    TimeSelectionComponent,
    DateSelectionComponent,
    TimeZoneSelectionComponent,
    SessionreviewComponent,
    LanguageComponent,
    PreventBlacklistCharactersDirective,
    ExpiringSlaProgramsBannerComponent,
    ExpiredSiteLicenseToastComponent,
    PaymentDefinitionComponent,
    ConfirmSessionComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TopazGlobalBarModule,
    TopazFooterModule,
    HttpClientModule,
    CertiportLayoutLibraryModule,
    TranslocoRootModule,
    FontAwesomeModule,
    CertiportLoginLibraryModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatCardModule,
    MatButtonModule,
    CdkTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatSelectModule,
    MatCheckboxModule,
    MatStepperModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatRadioModule,
    MatTabsModule,
    MatIconModule,
    MatListModule,
    MatDateFnsModule,
    MatTooltipModule,
    TopazBannerModule,
    TopazSideSheetModule,
    TopazTimepickerModule,
    TopazToastModule,
    TopazSearchModule,
    TopazCapsuleModule,
    TopazLoadingModule,
    MatAutocompleteModule,
    MatDialogModule,
    CommonLibModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
    {
      provide: 'environment',
      useValue: environment,
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        formFieldAppearance: 'outline',
      },
    },
    {
      provide: MatPaginatorIntl,
      useClass: TopazPaginatorIntl,
    },
    {
      provide: PaginatorIntlService,
      useClass: PaginatorIntlService,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: topazDefaultDialogOptions,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: enUS,
    },
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'layoutlib',
    },
    CanDeactivateGuard,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHref,
      deps: [PlatformLocation]
    },
    {
      provide: MAT_DIALOG_SCROLL_STRATEGY,
      useFactory: TOPAZ_BLOCK_SCROLL_STRATEGY,
      deps: [Overlay]
    }, ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [ConfigService]
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
