import { Component } from '@angular/core';
import { SidenavService } from '../../services/sidenav.service';

@Component({
  selector: 'app-network-test',
  templateUrl: './network-test.component.html',
  styleUrl: './network-test.component.scss'
})
export class NetworkTestComponent {
  isChecked = false;

  constructor(
    private sidenavService: SidenavService
  ) { }

  ngOnInit() {
    this.sidenavService.sidenavSubject
      .next({
        heading: 'connectioncheck.header',
        buttonName: '',
        showArrow: false
      });
  }
}
