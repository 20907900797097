import { Component, Inject, Injector, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslocoService, TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { TopazSidenavListItem } from '@pearsonvue/topaz-angular-ui';
import { faObjectsColumn, faCalendarDay, faGear, faSignalStream, faArrowLeftLong } from '@fortawesome/pro-regular-svg-icons';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NgIf } from '@angular/common';
import { SidenavService } from '../../../services/sidenav.service';

@Component({
  selector: 'app-mb-side-nav',
  templateUrl: './mb-side-nav.component.html',
  styleUrl: './mb-side-nav.component.scss'
})
export class MbSideNavComponent implements OnInit {
  @Input() userName: string = "";
  showNav: boolean = false;

  @Input() heading: string = '';
  @Input() buttonName: string = '';

  @Input() showArrow: boolean = false;

  public isMobile: boolean = false;

  public readonly icons = { faArrowLeftLong };
  private _smsFeatureEnabled = false;
  @Input() set smsFeatureEnabled(value: boolean) {
    this._smsFeatureEnabled = value;
    this.buildNavList();
  }
  get smsFeatureEnabled(): boolean {
    return this._smsFeatureEnabled;
  }

  navListItems: { section?: string; links: TopazSidenavListItem[] }[] = [
    {
      links: []
    }
  ];
  constructor(
    private breakPointObserver: BreakpointObserver,
    private injector: Injector,
    private translocoService: TranslocoService,
    private sideNavService: SidenavService
  ) { }

  ngOnInit(): void {
    this.breakPointObserver
      .observe('(max-width: 37.49rem)')
      .subscribe(value => this.isMobile = value.matches);

    this.buildNavList();
    this.sideNavService.showSideNav$.subscribe(val => {
      this.showNav = val;
    });
  }

  public onClickArrow() {
    this.sideNavService.arrowClickSubject?.next();
  }

  public onClickButton() {
    this.sideNavService.buttonClickSubject?.next();
  }

  private buildNavList() {
    this.navListItems = [{ links: [] }]; //clear navlist
    this.translocoService.selectTranslate('navItem.dashboard')
      .subscribe(
        value => {
          this.navListItems = [{
            links: [
              {
                title: this.translocoService.translate("navItem.dashboard"),
                path: '/dashboard',
                icon: faObjectsColumn
              },
              {
                title: this.translocoService.translate("navItem.sessionSchedule"),
                path: '/session-schedule',
                icon: faCalendarDay
              },
              ...(this.smsFeatureEnabled
                ? [{
                  title: this.translocoService.translate("navItem.preferences"),
                  path: '/preferences',
                  icon: faGear
                }]
                : []),
              {
                title: this.translocoService.translate("navItem.connectionCheck"),
                path: '/network-test',
                icon: faSignalStream
              }
            ]
          }];
        }
      );
  }
}
