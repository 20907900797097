<div class="exam-list-grid" *transloco="let t">
  <div class="grid-col-span-2">
    <mat-form-field appearance="outline">
      <mat-label>{{ t('createsession.examdetails.programs') }}</mat-label>
      <mat-select panelClass="topaz-select"
                  [formControl]="programSelect"
                  (selectionChange)="filterExamsByProgramId($event.value)">
        <mat-option [value]="0">{{ t('createsession.examdetails.allPrograms') }}</mat-option>
        <mat-option *ngFor="let program of programs" [value]="program.programID">{{ program.programName }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="grid-col-span-2 searchBarDiv">
   	<topaz-search
			placeholder="{{ t('createsession.examdetails.search') }}"
			(ngModelChange)="onSearchFilter($event)"
      [formControl]="searchInput"
			clearButtonAriaLabel="{{ t('createsession.examdetails.clear.search') }}"
			searchButtonAriaLabel="{{ t('createsession.examdetails.perform.search') }}"
      (clear)="onSearchClear()">
		</topaz-search>
  </div>

  <div class="grid-col-span-4 mt-neg-1">
    <mat-card class="topaz-table-container exam-list-card">

      <table cdk-table [dataSource]="examsListData" class="topaz-table full-width" matSort>
        <caption>{{ t('createsession.examdetails.examSelectionList') }}</caption>
        <ng-container cdkColumnDef="program">
          <th scope="col" cdk-header-cell *cdkHeaderCellDef mat-sort-header>
            {{ t('createsession.examdetails.programName') }}
          </th>
          <th scope="row" cdk-cell *cdkCellDef="let examSelect"
              [ngClass]="{'selected':examSelect.selected, '': !examSelect.selected}">
            <span class="topaz-table-mobile-header word-wrap">{{ t('createsession.examdetails.programName') }}</span>
            {{ examSelect.program.programName }}
          </th>
        </ng-container>
        <ng-container cdkColumnDef="exam">
          <th scope="col" cdk-header-cell *cdkHeaderCellDef mat-sort-header>{{ t('createsession.examdetails.examName') }}</th>
          <td cdk-cell *cdkCellDef="let examSelect" [ngClass]="{'selected':examSelect.selected, '': !examSelect.selected}">
            <span class="topaz-table-mobile-header word-wrap">{{ t('createsession.examdetails.examName') }}</span>
            {{ examSelect.exam.examName }} {{ examSelect.exam.autoDeskVersion }}
          </td>
        </ng-container>
        <ng-container cdkColumnDef="selectExam">
          <th scope="col" cdk-header-cell *cdkHeaderCellDef>
            <span class="sr-only">{{ t('createsession.examdetails.select') }}</span>
          </th>
          <td cdk-cell *cdkCellDef="let examSelect" data-title="Symbol" class="topaz-row-option"
              [ngClass]="{'selected':examSelect.selected, '': !examSelect.selected}">
            <button mat-button color="primary" class="topaz-button-small contrast_1"
              attr.aria-label="{{ (examSelect.selected ? t('generic.selected') : t('generic.select')) + ' ' + examSelect.exam.examName + ' ' + t('createsession.examdetails.exam')}}"
              (click)="selectedExamChange.emit(examSelect)">
              {{ examSelect.selected ? t('generic.selected') : t('generic.select') }}
            </button>
          </td>
        </ng-container>

        <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
        <tr cdk-row *cdkRowDef="let row; columns: displayedColumns" [ngClass]="{'selected':row.selected, '': !row.selected}"></tr>
        <ng-template cdkNoDataRow>
          <div class="tableMessage">
            {{ t('createsession.examdetails.no_exams_found') }}
          </div>
        </ng-template>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10]" *ngIf="isExamExist()"
        attr.aria-label="{{ t('examTablePaginator.ariaLabel')}}">
      </mat-paginator>
    </mat-card>
  </div>
</div>
