import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationOutput, AuthenticationService, EnvironmentVariablesService, IUserDetail, LoginErrorsOutput, LoginStorageTokens, UserDetailService } from '@certiport/login-library';
import { TranslocoService, TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { BannerService, TopazBannerType } from '@pearsonvue/topaz-angular-ui';
import { catchError, map, tap } from 'rxjs';
import { AllowedRoles } from '../../models';
import { IBannerData } from '../../models/bannerdata';
import { ConfigService } from '../../services/config-service';
import { BannerCustomComponent } from '../banner-custom/banner-custom.component';
import { SharedStateService } from 'src/app/services/shared-state.service';
import { SMSService } from 'src/app/services/sms.service';
import { SidenavService } from 'certiport-layout-library';
import { SettingsCacheService } from '../../services/settings-cache.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [{
    provide: TRANSLOCO_SCOPE,
    useValue: 'cptlogin'
  }]
})
export class LoginComponent {
  returnUrl!: string;
  constructor(
    private env: EnvironmentVariablesService,
    private router: Router,
    private bannerService: BannerService<IBannerData>,
    private userDetailService: UserDetailService,
    private authService: AuthenticationService,
    private translocoService: TranslocoService,
    private configService: ConfigService,
    private sharedStateService: SharedStateService,
    private smsService: SMSService,
    private sidenavService: SidenavService,
    private route: ActivatedRoute,
    private settingsCache: SettingsCacheService,

  ) {
    this.env.setRootUrl(this.configService.authenticationServiceUrl);
    const refreshToken: string | null = localStorage.getItem(LoginStorageTokens.Refresh);

    this.sidenavService.showMobileNav$.next(false);
    this.smsService.isSMSSessionConfirmPage$.next(false);

    if (refreshToken)
      this.router.navigate(['/dashboard']);
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get administratorText(): string {
    return this.translocoService.translate('serverError.administrator');
  }

  handleLoggedIn(user: AuthenticationOutput): void {
    if (this.checkIfUserHasAccess(user?.UserRoles!)) {
      this.userDetailService.setUserDetail(this.buildUserDetail(user));
      //TODO: Right now we get the smsEnabled flag by calling GetSettings. It will only have the correct value if GetSettings is called after login.
      //We are in a time crunch so I am adding a second call to GetSettings on login. Please fix this later!
      this.settingsCache.loadOnLogin().pipe(
        map(loaded => {
          if (this.returnUrl === '/')
            this.sharedStateService.setInvokeSmsSignupPopup(true);
          this.router.navigateByUrl(this.returnUrl);
        }),
        catchError(error => {
          throw error;
        })
      ).subscribe();
      
    } else {
      this.authService.logout()
        .pipe(tap(_ => {
          this.showUnauthorizeAccessBanner();
          this.userDetailService.setUserDetail(this.buildEmptyUserDetail());
          localStorage.clear();
        }))
        .subscribe();
    }
  }

  handleLoginError(error: LoginErrorsOutput): void {
    this.openBanner('warn', error.header ?? error.content, error.content);
  }

  private checkIfUserHasAccess(userRoles: number[]): boolean {
    return userRoles.some(x => AllowedRoles[x]);
  }

  private buildUserDetail(user: AuthenticationOutput): IUserDetail {
    return {
      userName: user.UserDisplayName!,
      userId: user.UserId ?? 0,
      userRoles: user.UserRoles!,
      loginExpiry: new Date(user.LoginExpiry!)
    }
  }

  private buildEmptyUserDetail(): IUserDetail {
    return {
      userName: '',
      userId: 0,
      userRoles: [],
      loginExpiry: new Date(0)
    }
  }

  private showUnauthorizeAccessBanner() {
    this.openBanner('warn',
      this.translocoService.translate('login.unauthorizeaccess.header.you_do_not_have_permission'),
      this.translocoService.translate('login.unauthorizeaccess.content.contact_your_administrator'));
  }

  private openBanner(bannerDisplayType: TopazBannerType, bannerHeader: string, bannerContent: string): void {
    this.bannerService.open(BannerCustomComponent, {
      bannerType: bannerDisplayType,
      header: bannerHeader,
      contents: [bannerContent],
      disableCloseButton: true
    });
  }

}
