import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { SettingsCacheService } from './settings-cache.service';
import { ConfigService } from './config-service';
import { Country } from '../models/country';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  countries: BehaviorSubject<Country[] | undefined> = new BehaviorSubject<Country[] | undefined>(undefined);

  constructor(
    private httpClient: HttpClient
    , private configService: ConfigService
  ) { }

  loadCountries() {
    let uri = this.configService.schedulingServiceURL + '/country';
    this.httpClient.get<Country[]>(uri, { withCredentials: true }).pipe(
      map(countries => {
        this.countries.next(countries);
      }),
    ).subscribe();
  }
}
