<ng-container *ngIf="settingsLoaded">
  <div class="mr-2 ml-2 mt-2" *transloco="let t; ">
    <topaz-banner bannerType="primary" [useIcon]="showIcon" disableScrolling disableCloseButton *ngIf="!isEmailUpdated">
      <div banner-content>
        {{t('session-confirmation.Emailsuccessfullysent')}} <b>{{email}}</b>{{t('session-confirmation.followtheinstructionintheemailbeforestartingasession')}}
      </div>
      <div banner-action>
        <button mat-button class="banner-button" color="primary" (click)="changeEmail()">{{t('session-confirmation.Changeemail')}}</button>
      </div>
    </topaz-banner>
    <topaz-banner bannerType="success" [useIcon]="showIcon" disableScrolling disableCloseButton *ngIf="isEmailUpdated" class="cards">
      <div banner-content>
        {{t('session-confirmation.EmailUpdated')}} <b>{{email}}</b>{{t('session-confirmation.updatefollowtheinstructionintheemailbeforestartingasession')}}
      </div>
      <div banner-action>
        <button mat-button class="banner-button" color="primary" (click)="changeEmail()">{{t('session-confirmation.Changeemail')}}</button>
      </div>
    </topaz-banner>
    <topaz-banner-root [banners]="banners" usedirectbanners></topaz-banner-root>

    <div class="mt-2 mb-2">
      <h1 class="desktop" autofocus>{{t('session-confirmation.Yoursessionisscheduledwhatnext')}}</h1>
      <h2 class="mobile">{{t('session-confirmation.Yoursessionisscheduledwhatnext')}}</h2>
    </div>

    <mat-card role="region" aria-labelledby="long-card-title1" class="card" attr.aria-label="{{t('session-confirmation.matcard_arialabel')}}" title="longcardtitle1">
      <div class="card-content">
        <div class="img-container">
          <img class="img" src="assets/images/Recievesessionemail.svg" alt="{{t('session-confirmation.Recievesessionemails')}}" />
          <div class="img-spacer"></div>
      </div>
        <div>
          <mat-card-title class="mb-1">
            <h2 class="desktop">{{sessionConfirmationMatCardSequence.recievesessionemailsSection}}. {{t('session-confirmation.Recievesessionemails')}}</h2>
            <h5 class="mobile">{{sessionConfirmationMatCardSequence.recievesessionemailsSection}}. {{t('session-confirmation.Recievesessionemails')}}</h5>
          </mat-card-title>
          <mat-card-content>
            <span class="desktop" *ngIf="!isJapanese()">
              {{t('session-confirmation.ExamProctorEmail1')}} <br />{{t('session-confirmation.ExamProctorEmail2')}}
            </span>
            <span class="desktop" *ngIf="isJapanese()">
              {{t('session-confirmation.ExamProctorEmail1')}}
            </span>
            <span class="mobile" *ngIf="!isJapanese()">
              {{t('session-confirmation.ExamProctorEmail1')}} {{t('session-confirmation.ExamProctorEmail2')}}
            </span>
            <span class="mobile" *ngIf="isJapanese()">
              {{t('session-confirmation.ExamProctorEmail1')}}
            </span>
          </mat-card-content>
          <mat-card-actions align="start" class="btn-actions ml-0 mr-0">
            <button mat-stroked-button color="primary" class="topaz-button-small" (click)="resendConfirmationEmail()">
              {{t('session-confirmation.Resendconfirmationemail')}}
            </button>
          </mat-card-actions>
        </div>
      </div>
    </mat-card>

    <mat-card role="region" aria-labelledby="long-card-title2" class="card" attr.aria-label="{{t('session-confirmation.matcard_arialabel2')}}" title="longcardtitle2">
      <div class="card-content">
        <div class="img-container">
          <img class="img" src="assets/images/ReviewConfirmaionemail.svg" alt="{{t('session-confirmation.2.Reviewconfirmationemails')}}" />
          <div class="img-spacer"></div>
      </div>
        <div>
          <mat-card-title class="mb-1">
            <h2 class="desktop">{{sessionConfirmationMatCardSequence.reviewconfirmationemailsSection}}. {{t('session-confirmation.2.Reviewconfirmationemails')}}</h2>
            <h5 class="mobile">{{sessionConfirmationMatCardSequence.reviewconfirmationemailsSection}}. {{t('session-confirmation.2.Reviewconfirmationemails')}}</h5>
          </mat-card-title>
          <mat-card-content class="parent-list-item review-confirmation-email">
            <ul class="desktop">
              <li>
                <span> {{t('session-confirmation.ExamAdministrator')}}</span>
              </li>
            </ul>
            <span class="mobile">{{t('session-confirmation.ExamAdministrator')}}</span>
            <div class="child-list-item">
              <ul>
                <li>{{t('session-confirmation.ProctorLink')}}</li>
                <li>{{t('session-confirmation.AccessCode')}}</li>
              </ul>
            </div>
          </mat-card-content>
        </div>
      </div>
    </mat-card>
    <mat-card role="region" aria-labelledby="long-card-title3" class="card" attr.aria-label="{{t('session-confirmation.matcard_arialabel3')}}" title="longcardtitle3" *ngIf="isUserAssignedPayment">
      <div class="card-content">
        <div class="img-container">
          <img class="img" src="assets/images/session_confirm_ac_icon.svg" alt="{{t('session-confirmation.useraccesscodesection.header')}}" />
        </div>
        <div>
          <mat-card-title class="mb-1">
            <h2 class="desktop">{{sessionConfirmationMatCardSequence.useraccessCodeSection}}. {{t('session-confirmation.useraccesscodesection.header')}}</h2>
            <h5 class="mobile">{{sessionConfirmationMatCardSequence.useraccessCodeSection}}. {{t('session-confirmation.useraccesscodesection.header')}}</h5>
          </mat-card-title>
          <mat-card-content class="parent-list-item review-confirmation-email">
            <ul class="desktop">
              <li>
                <span> {{t('session-confirmation.useraccesscodesection.line1')}}</span>
              </li>
              <li>
                <span> {{t('session-confirmation.useraccesscodesection.line2')}}</span>
              </li>
            </ul>
            <span class="mobile">{{t('session-confirmation.useraccesscodesection.line1')}}</span>
            <div class="child-list-item">
              <ul>
                <li>{{t('session-confirmation.useraccesscodesection.line3')}}</li>
              </ul>
            </div>
          </mat-card-content>
        </div>
      </div>
    </mat-card>

    <mat-card role="region" aria-labelledby="long-card-title4" class="card" attr.aria-label="{{t('session-confirmation.matcard_arialabel4')}}" title="longcardtitle4">
      <div class="card-content">
        <div class="img-container">
          <img class="img" src="assets/images/confirmsession.svg" alt="{{t('session-confirmation.3.Confirmsession')}}" />
          <div class="img-spacer"></div>
      </div>
        <div>
          <header>
            <mat-card-title class="mb-1">
              <h2 class="desktop">{{sessionConfirmationMatCardSequence.confirmsessionSection}}. {{t('session-confirmation.3.Confirmsession')}}</h2>
              <h5 class="mobile">{{sessionConfirmationMatCardSequence.confirmsessionSection}}. {{t('session-confirmation.3.Confirmsession')}}</h5>
            </mat-card-title>
          </header>
          <mat-card-content class="parent-list-item">
            <ul>
              <li>
                <span class="desktop">{{t('session-confirmation.24hours1')}} {{t('session-confirmation.24hours2')}}</span>
                <span class="mobile">{{t('session-confirmation.24hours1')}} {{t('session-confirmation.24hours2')}}</span>
              </li>

              <li>{{t('session-confirmation.AssociatedProctorMustConfirmSession')}}</li>
              <li class="fw-600">{{t('session-confirmation.notconfirmed')}}</li>
              <li *ngIf="isJapanese()">{{t('session-confirmation.24hours3')}}</li>
            </ul>
          </mat-card-content>
        </div>
      </div>
    </mat-card>
    <mat-card role="region" aria-labelledby="short-card-title" class="card" attr.aria-label="{{t('session-confirmation.matcard_shortcard')}}" title="shordcardtitle1">
      <div class="card-content">
        <div class="img-container">
          <img class="img" src="assets/images/startexam.svg" alt="{{t('session-confirmation.StartSession')}}" />
          <div class="img-spacer"></div>
      </div>
        <div>
          <mat-card-title class="mb-1">
            <h2 class="desktop">{{sessionConfirmationMatCardSequence.startSessionSection}}. {{t('session-confirmation.StartSession')}}</h2>
            <h5 class="mobile">{{sessionConfirmationMatCardSequence.startSessionSection}}. {{t('session-confirmation.StartSession')}}</h5>
          </mat-card-title>
          <mat-card-content class="parent-list-item">
            <ul>
              <li>{{ t('session-confirmation.Youmaystart', { earlyMin:minStartSessionLeadTime}) }}</li>
              <li>{{t('session-confirmation.Candidates')}}</li>
            </ul>
          </mat-card-content>
        </div>
      </div>
    </mat-card>
    <div class="div-return-to-dahboard">
      <button mat-flat-button color="primary" class="backbutton" (click)="returnDashboard()"><fa-icon [icon]="getArrowIcon()"></fa-icon> {{t('session-confirmation.Return')}}</button>
    </div>
  </div>
</ng-container>
