<div *ngIf="(userName||showNav) && isMobile" class="mb-side-nav">
  <div>
    <nav>
      <topaz-sidenav [navItems]="navListItems"></topaz-sidenav>
    </nav>
  </div>

  <div>
    <h2 class="h2-header">
      <button mat-icon-button *ngIf="showArrow" (click)="onClickArrow()">
        <fa-icon [icon]="icons.faArrowLeftLong"></fa-icon>
      </button>
      {{ heading }}
    </h2>
  </div>

  <div class="grid-col-span-2 ml-1 mr-1" *ngIf="buttonName">
    <div class="buttons full-width">
      <button mat-flat-button color="primary" (click)="onClickButton()">{{ buttonName }}</button>
    </div>
  </div>
</div>
