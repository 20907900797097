import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { catchError, map, Observable, of, Subscription } from 'rxjs';

import { CtpDatePipe, SidenavService } from 'certiport-layout-library';
import { BannerService, LoaderService, TopazBannerData } from '@pearsonvue/topaz-angular-ui';

import { BannerCustomComponent } from '../../banner-custom/banner-custom.component';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

import { IBannerData, IConfirmDialogData } from '@cbb/models';
import { SMSService } from 'src/app/services/sms.service';
import { ToastManagerService } from 'src/app/services/toast-manager.service';
import { SettingsCacheService } from 'src/app/services/settings-cache.service';
import { SessionValidationService } from 'src/app/services/session-validation.service';
import { SchedulingErrorCodeService } from 'src/app/services/scheduling-error-code.service';
import { ISessionScheduleList, IUpdateSessionStatus, SessionStatus, SessionStatusIds } from 'src/app/models/session';

import { faBan, faCheckCircle, faEllipsis, faEllipsisVertical, faFilePen, faLaptop, faMessageLines } from '@fortawesome/pro-solid-svg-icons';
import { InvokeSessionDetails, SessionInvokeType, SharedStateService } from 'src/app/services/shared-state.service';

import { ISessionActionParam } from 'src/app/models/session-action-param';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-confirm-session',
  standalone: false,
  templateUrl: './confirm-session.component.html',
  styleUrl: './confirm-session.component.scss'
})
export class ConfirmSessionComponent {

  public readonly icons = {
    faEllipsis,
    faLaptop,
    faFilePen,
    faCheckCircle,
    faMessageLines,
    faBan,
    faEllipsisVertical,
  }

  sessionCode: string = '';
  startDateTimeDisplay: string = "";

  sessioninfoLoaded: boolean = false;
  showCreateSessionButton: boolean = false;
  showSystemCancelledBanner: boolean = false;

  smsSessionInfo!: ISessionScheduleList;

  subscriptions: Subscription[] = [];
  public SessionInvokeType = SessionInvokeType;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private smsService: SMSService,
    private ctpDatePipe: CtpDatePipe,
    private transloco: TranslocoService,
    private loaderService: LoaderService,
    private sidenavService: SidenavService,
    private bannerService: BannerService<IBannerData>,
    private toastManagerService: ToastManagerService,
    private settingsCacheService: SettingsCacheService,
    private sessionValidationService: SessionValidationService,
    private schedulingErrorCodeService: SchedulingErrorCodeService,
    private sharedStateService: SharedStateService,
    private router: Router,
    private loginService: LoginService,

  ) { }

  ngOnInit(): void {
    this.loaderService.showLoader();

    this.initSidenav();
    this.subscribeToSidenav();
    this.sidenavService.showMobileNav$.next(true);

    this.smsService.isSMSSessionConfirmPage$.next(true);

    this.route.queryParams.subscribe(params => {
      this.sessionCode = params['t'];
    });

    const getSettingsSubscription = this.getSettings().subscribe((result: boolean) => {
      if (result) {
        this.getSessioninfo();
      }
    });

    this.subscriptions.push(getSettingsSubscription);
  }

  private initSidenav() {
    this.sidenavService.sidenavSubject
      .next({
        heading: 'scheduleTableOptions.confirmSession',
        buttonName: '',
        showArrow: false
      });
  }

  private subscribeToSidenav() {
    this.subscriptions.push(this.sidenavService
      .arrowClickSubject
      .subscribe());
  }

  private getSettings(): Observable<boolean> {
    return this.settingsCacheService.load().pipe(
      map(() => {
        return true;
      }),
      catchError(error => {
        this.loaderService.hideLoader();
        this.showErrorToast(error);
        return of(false)
      })
    )
  }

  private getSessioninfo() {

    const getSessionSubscription = this.smsService.getSession(this.sessionCode).pipe(
      map(sessionInfo => {
        this.smsSessionInfo = {
          sessionId: sessionInfo.sessionId,
          sessionName: sessionInfo.sessionName,
          sessionStatus: sessionInfo.sessionStatus,
          sessionStatusId: sessionInfo.sessionStatusId,
          proctorId: sessionInfo.proctorId,
          updatedById: sessionInfo.updatedById,
        } as ISessionScheduleList;

        this.mapSettingDataToValidationService();

        this.sessionValidationService.validateLoggedInUser = false;

        this.showSystemCancelledBanner = (sessionInfo.updatedById <= 0);
        this.smsSessionInfo.numberOfExams = sessionInfo.sessionExams.length;
        this.smsSessionInfo.startDateTime = this.cleanupDate(sessionInfo.startDateTime);
        this.startDateTimeDisplay = this.ctpDatePipe.transform(sessionInfo.startDateTimeDisplay, 'MMMM d, y hh:mm a');
        this.smsSessionInfo.numberOfCandidates = sessionInfo.sessionExams.reduce((acc, exam) => acc + exam.candidateCount, 0);

        this.smsSessionInfo.disableConfirmSession = this.sessionValidationService.shouldConfirmSessionBeDisabled(this.smsSessionInfo);
        this.smsSessionInfo.disableStartSession = this.sessionValidationService.shouldStartSessionBeDisabled(this.smsSessionInfo);
        this.smsSessionInfo.disableEditSession = this.sessionValidationService.shouldEditSessionBeDisabled(this.smsSessionInfo.sessionStatus, this.smsSessionInfo.startDateTime);
        this.smsSessionInfo.disableCancelSession = (this.sessionValidationService.shouldCancelSessionBeDisabled(this.smsSessionInfo));

        this.showCreateSessionButton = (this.smsSessionInfo.sessionStatusId == SessionStatusIds.cancelled);

        this.displayBanner();

        this.sessioninfoLoaded = true
        this.loaderService.hideLoader();
      }),
      catchError(error => {
        this.loaderService.hideLoader();
        this.showErrorToast(error);
        return of();
      })
    ).subscribe();

    this.subscriptions.push(getSessionSubscription);
  }

  private cleanupDate(date: Date): Date {
    if (typeof date === 'string') {
      let t: string = date as unknown as string;
      if (!t.includes('Z')) {
        t += 'Z';
        date = new Date(Date.parse(t))
      }
      else {
        date = new Date(Date.parse(t))
      }
    }
    return date;
  }

  private mapSettingDataToValidationService() {
    this.sessionValidationService.minConfirmSessionMinutes = this.settingsCacheService.minConfirmationLeadTime;
    this.sessionValidationService.maxConfirmSessionMinutes = this.settingsCacheService.maxConfirmSessionMinutes;
    this.sessionValidationService.minCancellationLeadTime = this.settingsCacheService.minCancellationLeadTime;
    this.sessionValidationService.minStartSessionLeadTime = this.settingsCacheService.minStartSessionLeadTime;
    this.sessionValidationService.maxStartSessionLateTime = this.settingsCacheService.maxStartSessionLateTime;
    this.sessionValidationService.maxEditTime = this.settingsCacheService.maxEditTime;
  }

  private displayBanner() {
    const statusesForBannerDisplay = [SessionStatusIds.scheduled, SessionStatusIds.confirmed, SessionStatusIds.cancelled];

    if (statusesForBannerDisplay.includes(this.smsSessionInfo.sessionStatusId)) {
      let bannerHeader = "";
      let bannerContent = "";
      let bannerType = "alert";

      if (this.smsSessionInfo.sessionStatusId == SessionStatusIds.scheduled) {
        bannerContent = this.transloco.translate("smsConfirmation.scheduledSessionBannerText");
      }
      else if (this.smsSessionInfo.sessionStatusId == SessionStatusIds.confirmed) {
        bannerType = "success";
        bannerHeader = this.transloco.translate("smsConfirmation.confirmedSessionBannerText", { sessionName: this.smsSessionInfo.sessionName });
      }
      else if (this.smsSessionInfo.sessionStatusId == SessionStatusIds.cancelled && !this.showSystemCancelledBanner) {
        bannerContent = this.transloco.translate("smsConfirmation.userCancelledSessionBannerText");
      }
      else if (this.smsSessionInfo.sessionStatusId == SessionStatusIds.cancelled && this.showSystemCancelledBanner) {
        const minConfirmationLeadHour = Math.floor(this.settingsCacheService.minConfirmationLeadTime / 60); 
        bannerContent = this.transloco.translate("smsConfirmation.systemCancelledSessionBannerText", { minConfirmationLeadTime: minConfirmationLeadHour });
      }

      this.bannerService.open(BannerCustomComponent, {
        bannerType: bannerType,
        header: bannerHeader,
        contents: [bannerContent],
        disableCloseButton: true
      } as TopazBannerData & IBannerData);
    }
  }

  updateSessionStatusToConfirmed() {
    this.updateSessionStatus(SessionStatusIds.confirmed);
  }

  updateSessionStatusToCancelled() {

    const dialogHeader = this.transloco.translate("cancelSession.cancelSessionHeader");

    var dialogContents: string[] = [];
    dialogContents.push(this.transloco.translate("smsConfirmation.confirmCancellationContent1"));
    dialogContents.push(this.transloco.translate("smsConfirmation.confirmCancellationContent2"));

    var confirmDialogData = {
      header: dialogHeader,
      contents: dialogContents,
      buttonType: "warn",
      showCloseButton: false,
      isCancelDialog: false
    } as IConfirmDialogData;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialogData
    })

    dialogRef.afterClosed().subscribe({
      next: (v) => {
        if (v === 'yes') {
          this.updateSessionStatus(SessionStatusIds.cancelled);
        }
      }
    })
  }

  private updateSessionStatus(toSessionStatusId: number) {

    this.loaderService.showLoader();

    let statusName = SessionStatus.confirmed;
    let toastHeader = this.transloco.translate("smsConfirmation.sessionConfirmed", { sessionName: this.smsSessionInfo.sessionName, candidateCount: this.smsSessionInfo.numberOfCandidates });

    if (toSessionStatusId == SessionStatusIds.cancelled) {
      statusName = SessionStatus.cancelled;
      this.showSystemCancelledBanner = false;
      toastHeader = this.transloco.translate("smsConfirmation.sessionCanceled", { sessionName: this.smsSessionInfo.sessionName });
    }

    let updateSessionStatus = {
      sessionCode: this.sessionCode,
      statusName: statusName,
      updatedById: this.smsSessionInfo.proctorId,
      reason: "canceled by admin"
    } as IUpdateSessionStatus;

    this.smsService.updateSessionStatus(updateSessionStatus).pipe(
      map(() => {
        this.smsSessionInfo.sessionStatusId = toSessionStatusId;
        this.smsSessionInfo.disableConfirmSession = true;
        this.smsSessionInfo.disableCancelSession = true;
        this.loaderService.hideLoader();

        this.toastManagerService.showTemporaryToast(toastHeader, "", "success");
      }),
      catchError(error => {
        this.showErrorToast(error);
        this.loaderService.hideLoader();
        return of();
      })
    ).subscribe();
  }

  private showErrorToast(error: any) {
    if (error?.error?.errorCode) {
      this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId);

      return;
    }
    this.schedulingErrorCodeService.showGenericErrorToast();
  }

  loadSessionDetails(invokeType: SessionInvokeType) {

    if (this.settingsCacheService.iesFeatureEnabled) {
      this.redirectToIESLogin(invokeType);
      return;
    }

    this.sharedStateService.setInvokeSessionData({ invokeSessionData: true, invokeType: invokeType, sessionId: this.smsSessionInfo.sessionId } as InvokeSessionDetails)
    this.router.navigate(['/dashboard']);
  }


  private redirectToIESLogin(invokeType: SessionInvokeType) {

    let action='';

    switch(invokeType) {
      case SessionInvokeType.ViewSession:
        action='view';
        break;
      case SessionInvokeType.EditSession:
        action='edit';
        break;
    }

    const sessionActionParam: ISessionActionParam = { action: action, code: this.sessionCode };

    const params = JSON.stringify(sessionActionParam);

    this.loginService.redirectToIESLogin(params);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub?.unsubscribe());
  }
}
