<ng-container *ngIf="settingsLoaded">
<topaz-side-sheet-content *transloco="let t;">
  <div side-sheet-header>
    <h4 autofocus>{{t('sessionview.sessionDetails')}}</h4>
  </div>
  <div side-sheet-content>
    <app-banner-edit-session></app-banner-edit-session>
    <div class="view-session">
      <div class="nameSession">
        <mat-label>{{t('sessionview.sessionName')}}</mat-label>
        <h4>{{sessionInfo.sessionName}}</h4>
      </div>
      <div>
        <mat-divider></mat-divider>
      </div>
      <div class="grid-container">
        <div>
          <mat-label>{{t('sessionview.testCenterName')}}</mat-label>
          <div>{{sessionInfo.certiportId}} - {{sessionInfo.testCenterName}}</div>
        </div>
        <div>
          <mat-label> {{t('sessionview.paymentMethodName')}}</mat-label>
          <div>{{sessionInfo.paymentMethodName}}</div>
        </div>
        <div>
          <mat-label>{{t('sessionview.examGroupId')}}</mat-label>
          <div><span *ngIf="sessionInfo.examGroupId>0"> {{sessionInfo.examGroupId}}</span></div>
        </div>
        <div>
          <mat-label>{{t('sessionview.examLanguage')}}</mat-label>
          <div>{{sessionInfo.languageName}}</div>
        </div>
        <div>
          <mat-label>{{t('sessionview.creator')}}</mat-label>
          <div>{{sessionInfo.creator}}</div>
        </div>
        <div>
          <mat-label>{{t('sessionview.proctor')}}</mat-label>
          <div>{{sessionInfo.proctor}}</div>
        </div>
        <div>
          <mat-label>{{t('sessionview.dateTime')}}</mat-label>
          <div *ngIf="sessionInfo.startDateTimeDisplay">{{sessionInfo.startDateTimeDisplay | ctpDate:'MMMM d, y'}} {{sessionInfo.startDateTimeDisplay | ctpDate:(startTimeMinutes == 0 ? 'h a' : 'h:mm a')}}</div>
        </div>
        <div>
          <mat-label>{{t('sessionview.timezone')}}</mat-label>
          <div>{{sessionInfo.timeZoneName}}</div>
        </div>
      </div>
    </div>

    <div class="session-exams">
      <app-view-session-exams [sessionExams]="this.sessionInfo.sessionExams" [isUserAssignedPayment]="isUserAssignedPayment">
      </app-view-session-exams>
    </div>
  </div>
  <div side-sheet-footer>
    <button mat-flat-button [disabled]="isDisable" color="primary" (click)="editSession()">{{t('sessionview.edit')}}</button>
  </div>
</topaz-side-sheet-content>
</ng-container>
