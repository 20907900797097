<div cdkTrapFocus aria-modal="true" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-description">
    <div *transloco="let t">
        <div class="topaz-dialog-header">
            <h3 class="sms-dialog-header">{{ t("sms.signupPopup.header") }}</h3>
        </div>
        <div mat-dialog-content id="modal-description">
            <div>
                <img src="assets/images/smsdialogimg.png" alt="smsImg">
            </div>
            <div>
                <p>{{ t("sms.signupPopup.content1") }}</p>
                <p>{{ t("sms.signupPopup.content2") }}</p>
            </div>
            <div>
                <h3 class="sms-confirmation-header">{{ t("pref.sessionConfirmReminderHeading") }}</h3>
                <div>
                    <ul class="sms-confirmation-list">
                        <li>{{ t("pref.confirmationTimingNote", {confirmMsgHours: firstConfirmationTextHours}) }}</li>
                        <li>{{ t("pref.lastConfirmationTimingNote", {lastConfirmMsgHours: secondConfirmationTextHours})
                            }}</li>
                    </ul>
                </div>
            </div>
            <div class="dont-show-again-div">
                <mat-checkbox [(ngModel)]="isDontShowThisAgainChecked">{{ t("sms.signupPopup.dontShowThisAgain") }}</mat-checkbox>
            </div>
        </div>
        <div matDialogActions align="end">
            <button mat-button (click)="skipOrSignup('Skip')" class="mat-action-button">{{ t("sms.signupPopup.skipSignUp") }}</button>
            <button mat-flat-button color="primary" (click)="skipOrSignup('Signup')">
                {{ t("sms.signupPopup.signUp") }}
            </button>
        </div>
    </div>
</div>