
<app-preferences [firstConfirmationTextMinutes]="firstConfirmationTextMinutes"
                 [secondConfirmationTextMinutes]="secondConfirmationTextMinutes"
                 [inEditMode]="inEditMode"
                 [communicationPreferences]="communicationPreferences"
                 [smsCountries]="smsCountries"
                 (onClickedSave)="handleClickedSave($event)"
                 (onClickedEdit)="handleClickedEdit()"
                 (onClickedCancel)="handleClickedCancel()">
</app-preferences>
