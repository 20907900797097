import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export interface Sidenav {
  heading: string;
  buttonName: string;
  showArrow: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  arrowClickSubject = new Subject<void>();
  buttonClickSubject = new Subject<void>();
  sidenavSubject = new Subject<Sidenav>();
  showSideNav$ = new BehaviorSubject<boolean>(false);
}
