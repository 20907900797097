<div *transloco="let t">
  <table cdk-table [dataSource]="matDataSource" class="topaz-table full-width session-schedule" matSort>
    <caption>
      Table of elements
    </caption>

    <ng-container cdkColumnDef="sessionName">
      <th scope="col" cdk-header-cell class="session-name-header" *cdkHeaderCellDef mat-sort-header>
        {{ t('scheduleTable.sessionName') }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="session-name">
        <span class="topaz-table-mobile-header word-wrap"> {{ t('scheduleTable.sessionName') }}</span>
        {{ element.sessionName }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="sessionStatus">
      <th scope="col" cdk-header-cell *cdkHeaderCellDef>
        {{ t('scheduleTable.sessionStatus') }}
      </th>
      <td cdk-cell *cdkCellDef="let element" [ngSwitch]="element.sessionStatus">
        <span class="topaz-table-mobile-header word-wrap"> {{ t('scheduleTable.sessionStatus') }}</span>
        <div class="capsule-div" *ngSwitchCase="'Confirmed'">
          <topaz-capsule color="accent">
            <fa-icon class="session-status-icon session-status-icon" [icon]="icons.faCheckCircle"></fa-icon>
            {{ t('scheduleTableStatus.confirmed') }}
          </topaz-capsule>
        </div>
        <div class="capsule-div" *ngSwitchCase="'Confirm Session'">
          <topaz-capsule color="alert">
            <fa-icon class="session-status-icon confirmed-Session-icon" [icon]="icons.faMessageLines"></fa-icon>
            <span class="confirmed-Session-label"> {{ t('scheduleTableStatus.confirmSession') }}</span>
          </topaz-capsule>
        </div>
        <div class="capsule-div" *ngSwitchCase="'In-progress'">
          <topaz-capsule>
            <fa-icon class="session-status-icon inprogress-icon" [icon]="icons.faLaptop"></fa-icon>
            <span class="inprogress-label">{{ t('scheduleTableStatus.inprogress') }}</span>
          </topaz-capsule>
        </div>
        <div class="capsule-div" *ngSwitchCase="'Complete'">
          <topaz-capsule>
            <fa-icon class="session-status-icon complete-icon" [icon]="icons.faCheckCircle"></fa-icon>
            <span class="complete-label"> {{ t('scheduleTableStatus.completeSession') }}</span>
          </topaz-capsule>
        </div>
        <div class="capsule-div" *ngSwitchCase="'User Cancellation'">
          <topaz-capsule color="warn">
            <fa-icon class="session-status-icon user-cancellation-icon" [icon]="icons.faBan"></fa-icon>
            <span class="complete-label"> {{ t('scheduleTableStatus.userCancellationSession') }}</span>
          </topaz-capsule>
        </div>
        <div class="capsule-div" *ngSwitchCase="'System Cancellation'">
          <topaz-capsule color="warn">
            <fa-icon class="session-status-icon system-cancellation-icon" [icon]="icons.faBan"></fa-icon>
            <span class="complete-label"> {{ t('scheduleTableStatus.systemCancellationSession') }}</span>
          </topaz-capsule>
        </div>
        <div class="capsule-div" *ngSwitchCase="'Expired-not started'">
          <topaz-capsule color="warn">
            <fa-icon class="session-status-icon system-cancellation-icon" [icon]="icons.faBan"></fa-icon>
            <span class="complete-label"> {{ t('scheduleTableStatus.expiredNotStarted') }}</span>
          </topaz-capsule>
        </div>
        <div class="capsule-div" *ngSwitchDefault>
          <topaz-capsule color="primary">
            <fa-icon class="session-status-icon" [icon]="icons.faFilePen"></fa-icon>
            {{ t('scheduleTableStatus.scheduled') }}
          </topaz-capsule>
        </div>
      </td>
    </ng-container>

    <ng-container cdkColumnDef="startDateTimeDisplay">
      <th scope="col" cdk-header-cell *cdkHeaderCellDef mat-sort-header>
        {{ t('scheduleTable.dateAndTime') }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <span class="topaz-table-mobile-header word-wrap">{{ t('scheduleTable.dateAndTime') }}</span>
        {{element.startDateTimeDisplay | ctpDate:'MMMM d, y'}} {{ element.startDateTimeDisplay | ctpDate:'h:mm a'}}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="numberOfCandidates">
      <th scope="col" cdk-header-cell *cdkHeaderCellDef>
        {{ t('scheduleTable.numberOfCandidates') }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <span class="topaz-table-mobile-header word-wrap">{{ t('scheduleTable.numberOfCandidates') }}</span>
        {{ element.numberOfCandidates }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="numberOfExams">
      <th scope="col" cdk-header-cell *cdkHeaderCellDef>
        {{ t('scheduleTable.numberOfExams') }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <span class="topaz-table-mobile-header word-wrap">{{ t('scheduleTable.numberOfExams') }}</span>
        {{ element.numberOfExams }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="creator">
      <th scope="col" cdk-header-cell *cdkHeaderCellDef>
        {{ t('scheduleTable.creator') }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <span class="topaz-table-mobile-header word-wrap">{{ t('scheduleTable.creator') }}</span>
        {{ element.creatorName }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="proctor">
      <th scope="col" cdk-header-cell *cdkHeaderCellDef>
        {{ t('scheduleTable.proctor') }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <span class="topaz-table-mobile-header word-wrap">{{ t('scheduleTable.proctor') }}</span>
        {{ element.proctorName }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="option">
      <th [hidden]="hideOptions" scope="col" cdk-header-cell *cdkHeaderCellDef class="topaz-row-option">
        <span>{{ t('scheduleTable.options') }}</span>
      </th>
      <td [hidden]="hideOptions" cdk-cell *cdkCellDef="let row" class="topaz-row-option">
        <button mat-icon-button [mat-menu-trigger-for]="optionMenu" [title]="t('scheduleTable.sessionOptions', {sessionName:row.sessionName})" attr.aria-label="{{t('scheduleTable.sessionOptions', {sessionName:row.sessionName})}}">
          <fa-icon [icon]="icons.faEllipsis" ></fa-icon>
        </button>

        <mat-menu #optionMenu="matMenu">
          <button mat-menu-item (click)="confirmSession(row.sessionId, row.sessionName, row.startDateTime)"  [disabled]="row.disableConfirmSession">
            {{ t('scheduleTableOptions.confirmSession') }}
          </button>
          <button mat-menu-item *ngIf="!row.disableResumeSession" (click)="resumeSession(row.sessionId)">
            {{ t('scheduleTableOptions.ResumeSession') }}
          </button>
          <button *ngIf="!row?.disableStartSession" mat-menu-item (click)="startSession(row.sessionId)" [disabled]="row.disableStartSession">
            {{ t('scheduleTableOptions.startSession') }}
          </button>
          <button mat-menu-item (click)="viewSession(row.sessionId)">
            {{ t('scheduleTableOptions.viewDetails') }}
          </button>
          <button mat-menu-item (click)="editSession(row.sessionId)" [disabled]="row.disableEditSession">
            {{ t('scheduleTableOptions.edit') }}
          </button>
          <button mat-menu-item (click)="resendConfirmationEmail(row.sessionId)">
            {{ t('scheduleTableOptions.resendConfirmationEmail') }}
          </button>
          <button mat-menu-item [ngClass]="{'topaz-warn': row.disableCancelSession === false}" (click)="cancelSession(row.sessionId, row.sessionName, row.startDateTime)" [disabled]="row.disableCancelSession">
            {{ t('scheduleTableOptions.cancelSession') }}
          </button>
        </mat-menu>
      </td>
    </ng-container>
    <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: displayedColumns" [ngClass]="{ selected: selection.isSelected(row) }">
    </tr>
  </table>
  <div *ngIf="isNoSessionExist()" class="no-data-section" >{{ noDataText }}</div>
  <mat-paginator [pageSizeOptions]="pageSizeOptions" *ngIf="isSessionExist()" [length]="sessionScheduleData.length"
                  [pageSize]="pageSize" attr.aria-label="{{ t('scheduleTablePaginator.ariaLabel') }}"></mat-paginator>
</div>
