import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { SettingsCacheService } from 'src/app/services/settings-cache.service';

@Component({
  selector: 'app-sms-sign-up-dialog',
  standalone: true,
  imports: [TranslocoModule, MatCheckboxModule, MatButtonModule, FormsModule],
  templateUrl: './sms-sign-up-dialog.component.html',
  styleUrl: './sms-sign-up-dialog.component.scss'
})
export class SmsSignUpDialogComponent {

  firstConfirmationTextHours = 0;
  secondConfirmationTextHours = 0;
  isDontShowThisAgainChecked = false;
  constructor(private transloco: TranslocoService,
    private settingsCache: SettingsCacheService,
    public dialogRef: MatDialogRef<SmsSignUpDialogComponent>,
  ) {
    this.firstConfirmationTextHours = this.settingsCache.maxConfirmSessionMinutes / 60;
    this.secondConfirmationTextHours = this.settingsCache.secondSessionConfirmationLeadTimeMinutes / 60;
  }

  public skipOrSignup(actionType: string) {
    this.dialogRef.close({ actionType: actionType, isDontShowThisAgainChecked: this.isDontShowThisAgainChecked });
  }
}
