<ng-container *ngIf="settingsLoaded">
  <div id="session-container" *transloco="let t">
    <div class="header" *ngIf="!isTouchUi">
      <div>
        <button class="arrow-icon-button" mat-icon-button (click)="onBack()" [attr.aria-label]="t('session-confirmation.Return')">
          <fa-icon [icon]="icons.faArrowLeftLong"></fa-icon>
        </button>
      </div>
      <div><h1 autofocus>{{ t('createsessionlayout.header.create_session') }}</h1></div>
    </div>

    <div id="stepper-container">
      <mat-stepper (click)="onStepClick()" [orientation]="(stepperOrientation | async)!" linear #stepper>
        <!-- icon overrides -->
        <ng-template matStepperIcon="edit">
          <mat-icon>done</mat-icon>
        </ng-template>

        <!-- Session Details -->
        <mat-step [stepControl]="sessionForm">
          <div class="stepper-content">
            <ng-template matStepLabel>
              <span class="step-label">{{ t('createsessionlayout.label.session_details') }}</span>
            </ng-template>
            <div id="session-details-container">
              <h2 class="h3-header mt-1_4">{{ t('sessiondetails.session_details') }}</h2>
              <p class="subtitle">{{ t('sessiondetails.session_subtitle') }}</p>
              <!-- SESSION DETAILS FORM -->
              <form [formGroup]="sessionForm">
                <div class="session-details-grid">
                  <!-- SESSION NAME INPUT -->
                  <div class="grid-col-span-2">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ t('sessiondetails.label.session_name') }}</mat-label>
                      <input type="text" preventBlacklistCharacters matInput formControlName="sessionName" required tabindex="0" />
                      <mat-error *ngIf="getControl('sessionName')?.hasError('required')">
                        {{ t('sessiondetails.error.session_name_required') }}
                      </mat-error>
                      <mat-error *ngIf="getControl('sessionName')?.hasError('maxlength')">
                        {{ t('sessiondetails.error.session_name_maxlength', { max: 250 }) }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <!-- TEST CENTER DROPDOWN -->
                  <div class="grid-col-span-2">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ t('sessiondetails.label.test_center') }}</mat-label>
                      <input type="text" placeholder="{{ t('sessiondetails.label.test_center') }}" matInput formControlName="testCenter"
                             [matAutocomplete]="autoTestCenter" />

                      <fa-icon [icon]="icons.faCaretDown" size="sm" matIconSuffix></fa-icon>
                      <mat-autocomplete autoActiveFirstOption="true" hideSingleSelectionIndicator="true" #autoTestCenter="matAutocomplete" [displayWith]="displayTestCenterName">
                        <mat-option *ngFor="let testCenter of filterTestCenters | async" [value]="testCenter">
                          {{ testCenter.certiportID }} - {{ testCenter.testCenterName }}
                        </mat-option>
                      </mat-autocomplete>

                      <mat-error *ngIf="getControl('testCenter')?.hasError('required')">
                        {{ t('sessiondetails.error.test_center_required') }}
                      </mat-error>
                      <mat-error *ngIf="getControl('testCenter')?.hasError('invalidAutocompleteObject')">
                        {{ t('sessiondetails.error.test_center_invalid') }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <!-- PAYMENT METHOD DROPDOWN -->
                  <div id="payment-method-container">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ t('sessiondetails.label.payment_method') }}</mat-label>
                      <mat-select panelClass="topaz-select" formControlName="paymentMethod" required (selectionChange)="CheckIsUserAssignedPaymentSelected()">
                        <mat-option *ngFor="let paymentMethod of paymentMethods" [value]="paymentMethod">
                          {{ paymentMethod.displayName }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="getControl('paymentMethod')?.hasError('required')">{{ t('sessiondetails.error.payment_method_required') }}</mat-error>
                    </mat-form-field>

                    <a id="paymentMethodDefinitionLink" (click)="openPaymentDefinitionsDialog()">{{t('paymentMethodDefinition.title')}}</a>
                  </div>

                  <!-- EXAM ID INPUT -->
                  <div>
                    <mat-form-field appearance="outline">
                      <mat-label>{{ t('sessiondetails.label.exam_group_id') }}</mat-label>

                      <input type="number" matInput formControlName="examGroupId" min="1" numberOnly />

                      <mat-error *ngIf="getControl('examGroupId')?.hasError('invalid')">
                        {{ t('createsession.sessiondetails.exam_group_id_invalid') }}
                      </mat-error>
                      <mat-error *ngIf="getControl('examGroupId')?.hasError('notFound')">
                        {{ t('createsession.sessiondetails.exam_group_id_not_found') }}
                      </mat-error>
                      <mat-error *ngIf="getControl('examGroupId')?.hasError('notAssociated')">
                        {{ t('createsession.sessiondetails.exam_group_id_not_associated') }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <!-- EXAM LANGUAGE DROPDOWN -->
                  <app-language></app-language>

                  <!-- SELECT PROCTOR DROPDOWN -->
                  <div>
                    <mat-form-field appearance="outline">
                      <mat-label>{{ t('sessiondetails.label.proctor') }}</mat-label>
                      <input type="text" placeholder="{{ t('sessiondetails.label.proctor') }}" matInput
                             formControlName="proctor" [matAutocomplete]="autoProctor" required />
                      <fa-icon [icon]="icons.faCaretDown" size="sm" matIconSuffix></fa-icon>

                      <mat-autocomplete autoActiveFirstOption="true" hideSingleSelectionIndicator="true" #autoProctor="matAutocomplete" [displayWith]="displayProctorName">
                        <mat-option *ngFor="let proctor of filteredproctors | async" [value]="proctor">
                          {{ proctor.firstName }} {{ proctor.lastName }}
                          <span *ngIf="proctor.userID === loggedInUserId"> ({{ t('createsession.sessiondetails.you') }})</span>
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error *ngIf="getControl('proctor')?.errors?.['required']">{{ t('sessiondetails.error.proctor_required') }}</mat-error>
                      <mat-error *ngIf="getControl('proctor')?.hasError('invalidAutocompleteObject')">
                        {{ t('sessiondetails.error.proctor_invalid') }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <!-- EXAM DATE PICKER -->
                <app-exam-date-time-region [maxDays]="maxDays"></app-exam-date-time-region>
              </form>
            </div>
          </div>

        <!-- Session Details action buttons -->
        <div>
          <mat-divider class="divider"></mat-divider>
        </div>
        <div class="step-action">
          <div class="aux-button-list"></div>
          <div class="main-button-list">
            <button mat-flat-button color="primary" class="topaz-button-small" (click)="onStep(Stepper.SessionDetails, ActionType.Next)">
              {{ t('generic.next') }}
            </button>
        </div>
        </div>
      </mat-step>

        <!-- Exam Selection -->
        <mat-step [stepControl]="examsForm">
          <ng-template matStepLabel><span class="step-label">{{ t('createsessionlayout.label.exam_details') }}</span></ng-template>
          <ng-template matStepContent>
            <div class="stepper-content">
              <div id="exam-details-container">
                <div id="exam-details-tabs">
                  <mat-tab-group [(selectedIndex)]="selectedExamTabIndex" mat-stretch-tabs="false" mat-align-tabs="start">
                    <form [formGroup]="examsForm">
                      <ng-container formArrayName="examForms">
                        <mat-tab *ngFor="let eForm of examForms.controls; index as i" label="{{ t('createsession.examdetails.exam') }} {{i+1}}">
                          <ng-container [formGroupName]="i">
                            <div [id]="'exam-details-container-' + i">
                              <h3 class="h3-header mt-0_3">{{ t('createsession.examdetails.header') }}</h3>
                              <p class="subtitle">{{ t('createsession.examdetails.instructions') }}</p>

                              <app-exams-list [allExamsAndPrograms]="allProgramsAndExams"
                                              [selectedExam]="eForm.get('examSelection')?.value"
                                              (selectedExamChange)="eForm.get('examSelection')?.patchValue($event);selectedExamChange()">
                              </app-exams-list>

                              <div class="exam-details-container">
                                <div class="exam-notes-container"> <!-- grid-col-span-4 max-candidate-note -->
                                  <p class="subtitle mb-1" *ngIf="!isUserAssignedPaymentSelected">{{ t('createsession.examdetails.maxCandidates', { max: maxCandidateCount }) }}</p>
                                  <p class="subtitle mb-1" *ngIf="isUserAssignedPaymentSelected">{{ t('user-assigned-voucher-notes') }} {{ t('session_max_candidate') }}</p>
                                </div>

                              <div class="exam-action-container">
                                <!-- Number of Candidates -->
                                <div class="candidate-count-container">
                                  <mat-form-field appearance="outline">
                                    <mat-label>{{ t('createsession.examdetails.numberOfCandidates') }}</mat-label>
                                    <input matInput formControlName="numCandidates" numberOnly [disabled]="isUserAssignedPaymentSelected" />
                                    <mat-error *ngIf="eForm.get('numCandidates')?.errors?.['max']">
                                      {{ t('createsession.examdetails.candidate_max_exceeded', { maxCandidates: maxCandidateCount }) }}
                                    </mat-error>
                                    <mat-error *ngIf="eForm.get('numCandidates')?.errors?.['required']">
                                      {{ t('createsession.examdetails.requiredNoOfCandidates') }}
                                    </mat-error>
                                  </mat-form-field>                                
                                </div>
  
                                  <!-- Action buttons -->
                                  <div class="exam-action-buttons-container" [id]="'exam-actions-buttons-' + i"> <!--exam-actions-buttons-->
                                  <div class="single-button-container">
                                      <button mat-button color="warn" *ngIf="examForms.length > 1" class="topaz-button-small action-button mr-0_5" (click)="onExamRemove(i)">
                                        <fa-icon [icon]="icons.faTrashCan"></fa-icon> {{ t('createsession.examdetails.removeExam') }}
                                      </button>
                                    </div>
                                  <div class="single-button-container">
                                    <button mat-button class="topaz-button-small action-button" [disabled]="isAddExamDisable" *ngIf="examForms.length < 5" (click)="onExamAdd(examForms.length)">
                                        <fa-icon [icon]="icons.faPlus"></fa-icon> {{ t('createsession.examdetails.addExam') }}
                                      </button>
                                    </div>
                                </div>
                              </div>
                              </div>
                            </div>
                          </ng-container>
                        </mat-tab>
                      </ng-container>
                    </form>
                  </mat-tab-group>
                </div>
              </div>
            </div>

          <!-- Exam Selection action buttons -->
          <mat-divider class="divider"></mat-divider>
          <div class="step-action">

            <div class="aux-button-list">
              <button mat-button class="topaz-button-small ml-2" (click)="onCancelSession()">
                {{ t('generic.cancel_session') }}
              </button>
            </div>

            <div class="main-button-list">
              <button mat-stroked-button color="primary" class="topaz-button-small mr-0_5" (click)="onStep(Stepper.ExamDetails, ActionType.Previous)">
                {{ t('generic.previous') }}
              </button>
              <button mat-flat-button color="primary" class="topaz-button-small" (click)="onStep(Stepper.ExamDetails, ActionType.Next)">
                {{ t('generic.next') }}
              </button>
            </div>

          </div>
        </ng-template>
      </mat-step>

        <!-- Session Review -->
        <mat-step>
          <ng-template matStepLabel>
            <span class="step-label">{{ t('createsessionlayout.label.review_and_submit') }}</span>
          </ng-template>
          <ng-template matStepContent>
            <app-sessionreview [examForms]="examForms" [sessionForm]="sessionForm" (sessionEdit)="onSessionEdit()" (examEdit)="onExamEdit($event)" [maxHours]="maxHours" [isUserAssignedPaymentSelected]="isUserAssignedPaymentSelected" [certiportId]="selectedTestCenter?.certiportID"></app-sessionreview>
              <mat-divider class="divider"></mat-divider>
  
          <!-- Session Review action buttons -->
          <div class="step-action">
              <div class="aux-button-list">
              <button mat-button class="topaz-button-small ml-2" (click)="onCancelSession()">
                  {{ t('generic.cancel_session') }}
                </button>
              </div>

            <div class="main-button-list">
                <button mat-stroked-button color="primary" class="topaz-button-small mr-0_5" (click)="onStep(Stepper.ReviewAndSubmit, ActionType.Previous)">
                  {{ t('generic.previous') }}
                </button>
                <button mat-flat-button color="primary" [disabled]="isSessionSubmitted" class="topaz-button-small" (click)="onSubmit()">
                  {{ t('generic.submit') }}
                </button>
              </div>
            </div>
  
        </ng-template>
        </mat-step>
        <!-- Session Details action buttons -->
      </mat-stepper>

    <!-- Mobile view -->
    <div class="mb-step-container">
      <mat-divider></mat-divider>

        <ng-container [ngSwitch]="activeStepper">

          <div class="mb-step-action" *ngSwitchCase="Stepper.SessionDetails">
            <div class="mb-aux-button-list"></div>
          <div class="mb-main-button-list">
              <button mat-button class="topaz-button-small mb-action-button" (click)="onCancelSession()">
                {{ t('generic.cancel_session') }}
              </button>
              <button mat-flat-button color="primary" class="topaz-button-small mb-action-button" (click)="onStep(Stepper.SessionDetails, ActionType.Next)">
                {{ t('generic.next') }}
              </button>
            </div>
          </div>

          <div class="mb-step-action" *ngSwitchCase="Stepper.ExamDetails">
            <div class="mb-aux-button-list">
            <button mat-button class="topaz-button-small mb-action-button" (click)="onCancelSession()">
                {{ t('generic.cancel_session') }}
              </button>
          </div>

            <div class="mb-main-button-list">
              <button mat-stroked-button color="primary" class="topaz-button-small mb-action-button" (click)="onStep(Stepper.ExamDetails, ActionType.Previous)">
                {{ t('generic.previous') }}
              </button>
              <button mat-flat-button color="primary" class="topaz-button-small mb-action-button" (click)="onStep(Stepper.ExamDetails, ActionType.Next)">
                {{ t('generic.next') }}
              </button>
            </div>
          </div>

          <div class="mb-step-action" *ngSwitchCase="Stepper.ReviewAndSubmit">
            <div class="mb-aux-button-list">
            <button mat-button class="topaz-button-small mb-action-button" (click)="onCancelSession()">
                {{ t('generic.cancel_session') }}
              </button>
          </div>

            <div class="mb-main-button-list">
              <button mat-stroked-button color="primary" class="topaz-button-small mb-action-button" (click)="onStep(Stepper.ReviewAndSubmit, ActionType.Previous)">
                {{ t('generic.previous') }}
              </button>
              <button mat-flat-button color="primary" class="topaz-button-small mb-action-button" (click)="onSubmit()">
                {{ t('generic.submit') }}
              </button>
            </div>
          </div>
        </ng-container>
      </div>

    </div>

</div>
</ng-container>
