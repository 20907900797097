<div cdkTrapFocus
     aria-modal="true"
     role="dialog"
     [attr.aria-label]= "  t.translate('ConfirmDialog.modal-title')"
     [attr.aria-describ]= " t.translate('ConfirmDialog.modal-description')">
  <div class="topaz-dialog-header" *transloco="let t">
    <h1 topazTitle="3">{{ data.header }}</h1>
    <button mat-icon-button mat-dialog-close attr.aria-label="{{ t('generic.close_dialog') }}"  *ngIf="data.showCloseButton" (click)="this.dialogRef.close()">
      <fa-icon title="close" size="lg" [icon]="icons.faXmark"></fa-icon>
    </button>
  </div>
  <div *ngFor="let content of data.contents;let i = index">
    <p *ngIf="i==0">
      {{ content }}
    </p>
    <p *ngIf="i==1">
      {{ content }}<strong *ngIf="data.isCancelDialog"> &nbsp;{{ data.contents[2] }}</strong><span *ngIf="data.isCancelDialog">&nbsp;?</span>
    </p>
  </div>
  <div class="mt-2" mat-dialog-actions [align]="'end'">
    <button mat-button mat-dialog-close="no" (click)="this.dialogRef.close()">{{noButtonText}}</button>
    <button mat-flat-button [color]="data.buttonType" mat-dialog-close="yes" (click)="this.dialogRef.close('yes')">{{yesButtonText}}</button>
  </div>
</div>