import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { catchError, map, tap } from "rxjs/operators";
import { AuthenticationService, AutoLogoutTimerService, UserDetailService } from "@certiport/login-library";
import { EditViewSidesheetService } from "../../services/edit-view-sidesheet.service";
import { SettingsCacheService } from "../../services/settings-cache.service";

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

  private redirectCodes: number[] = [401];

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private editViewSidesheetService: EditViewSidesheetService,
    private autoLogoutTimerService: AutoLogoutTimerService,
    private userDetailService: UserDetailService,
    private settingsCacheService: SettingsCacheService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('/refreshtoken') > -1) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      tap(() => { },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (this.redirectCodes.indexOf(err.status) === -1) {
              return;
            }
            localStorage.clear();
            this.editViewSidesheetService.closeSideSheets();
            this.logout();
            this.router.navigate(['/login']);
          }
        }));
  }

  private logout() {
    this.userDetailService.setUserDetail({ userId: 0, userName: '', userRoles: [], loginExpiry: new Date(0), portalUserSessionId: '' }); //This has to be done to stop showing the sidenav
    this.settingsCacheService.userLoggedIn = false;
    this.autoLogoutTimerService.stop();
    this.authenticationService.logout().pipe(
      map(() => {
        this.editViewSidesheetService.closeSideSheets();
        this.router.navigate(['/login']);
      }),
      catchError(error => {
        return of();
      }))
      .subscribe();
  }
}
