import { Component, Injector, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { TopazSidenavListItem } from '@pearsonvue/topaz-angular-ui';
import { faObjectsColumn, faCalendarDay, faGear, faSignalStream } from '@fortawesome/pro-regular-svg-icons';
import { SidenavService } from '../../services/sidenav.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss'
})
export class SideNavComponent implements OnInit {
  @Input() userName: string = "";
  private _smsFeatureEnabled = false;
  isMobile: boolean = false;
  showNav: boolean = false;


  @Input() set smsFeatureEnabled(value: boolean) {
    this._smsFeatureEnabled = value;
    this.buildNavList();
  }
  get smsFeatureEnabled(): boolean {
    return this._smsFeatureEnabled;
  }

  navListItems: { section?: string; links: TopazSidenavListItem[] }[] = [
    {
      links: []
    }
  ];
  constructor(
    private injector: Injector,
    private translocoService: TranslocoService,
    private sideNavService: SidenavService
) { }

  ngOnInit(): void {

    this.buildNavList();
    this.sideNavService.showSideNav$.subscribe(val => {
      this.showNav = val;
    });
  }
  isUserLoggedIn() {
    if (!this.userName || this.userName.length === 0 || this.userName == "null" || this.userName.trim() == "" || this.userName.trim().length == 0) {
      return false;
    }
    return true;
  }

  private buildNavList() {
    this.navListItems = [{ links: [] }]; //clear navlist
    this.translocoService.selectTranslate('navItem.dashboard')
      .subscribe(
        value => {
          this.navListItems = [{
            links: [
              {
                title: this.translocoService.translate("navItem.dashboard"),
                path: '/dashboard',
                icon: faObjectsColumn
              },
              {
                title: this.translocoService.translate("navItem.sessionSchedule"),
                path: '/session-schedule',
                icon: faCalendarDay
              },
              ...(this.smsFeatureEnabled
                ? [{
                  title: this.translocoService.translate("navItem.preferences"),
                  path: '/preferences',
                  icon: faGear
                }]
                : []),
              {
                title: this.translocoService.translate("navItem.connectionCheck"),
                path: '/network-test',
                icon: faSignalStream
              }
            ]
          }];
        }
      );
  }
}
