<ng-container *ngIf="settingsLoaded">
<topaz-side-sheet-content *transloco="let t">
  <div side-sheet-header>
    <h4>{{t('editSession.sessionDetails')}}</h4>
  </div>

  <div side-sheet-content>
    <app-banner-edit-session></app-banner-edit-session>
    <div class="edit-session">
      <form [formGroup]="editSessionForm">
        <div class="grid-container">
          <div class="session-name grid-col-span-2">
            <mat-form-field appearance="outline">
              <mat-label>{{t('editSession.sessionName')}}</mat-label>
              <input type="text" matInput preventBlacklistCharacters formControlName="sessionName" (input)="updateSession(); closeBanner();" tabindex="0">
              <mat-error *ngIf="formControls.sessionName?.touched && formControls.sessionName?.errors?.['invalidValue']">
                {{t('editSession.sessionNameRequired')}}
              </mat-error>

              <mat-error *ngIf="formControls.sessionName?.errors?.['maxlength']">
                {{t('editSession.sessionNameMaxlength')}}
              </mat-error>
            </mat-form-field>
          </div>


          <div class="read-only">
            <div>
              <mat-label class="mat-label">{{t('editSession.testCenter')}}</mat-label>
            </div>
            <div>
              <span>{{session.certiportId}} - {{session.testCenterName}}</span>
            </div>
          </div>
          <div class="read-only">
            <div>
              <mat-label class="mat-label">{{t('editSession.paymentMethod')}}</mat-label>
            </div>
            <div>
              <span>{{session.paymentMethodName}}</span>
            </div>
          </div>


          <div>
            <mat-form-field appearance="outline">
              <mat-label>{{t('editSession.examGroupId')}}</mat-label>
              <input type="number" numberOnly class="no-up-down-spinner" matInput formControlName="examGroupId" (input)="updateSession(); closeBanner()">
              <mat-error *ngIf="formControls.examGroupId?.errors?.['apiError']">
                {{examGroupErrorMsg}}
              </mat-error>
              <mat-error *ngIf="formControls.examGroupId?.errors?.['maxLengthExceed']">
                {{t('editSession.examGroupIdMaxLengthExceed')}}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="read-only">
            <div>
              <mat-label class="mat-label">{{t('editSession.examLanguage')}}</mat-label>
            </div>
            <div>
              <span>{{session.languageName}}</span>
            </div>
          </div>

          <div class="read-only">
            <div>
              <mat-label class="mat-label">{{t('editSession.creator')}}</mat-label>
            </div>
            <div>
              <span>{{session.creator}}</span>
            </div>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{t('editSession.proctor')}}</mat-label>
              <input type="text" placeholder="{{ t('sessiondetails.label.proctor') }}" matInput
                formControlName="proctor" [matAutocomplete]="autoProctor" required />
              <fa-icon [icon]="icons.faCaretDown" size="sm" matIconSuffix></fa-icon>
              
              <mat-autocomplete autoActiveFirstOption="true" hideSingleSelectionIndicator="true" #autoProctor="matAutocomplete" [displayWith]="displayProctorName" (optionSelected)="updateSession()">
                <mat-option *ngFor="let proctor of filteredproctors | async" [value]="proctor">
                  {{ proctor.firstName }} {{ proctor.lastName }}
                </mat-option>
              </mat-autocomplete>

              <mat-error *ngIf="formControls.proctor?.hasError('required')">
                {{t('editSession.proctorRequired')}}
              </mat-error>
              <mat-error *ngIf="getControl('proctor')?.hasError('invalidAutocompleteObject')">
                {{ t('sessiondetails.error.proctor_invalid') }}
              </mat-error>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field>
              <mat-label>{{t('editSession.timeZone')}}</mat-label>
              <input type="text" placeholder="{{ t('sessiondetails.label.proctor') }}" matInput
                formControlName="timeZone" [matAutocomplete]="autoTimeZone" required />
              <fa-icon [icon]="icons.faCaretDown" size="sm" matIconSuffix></fa-icon>
              <mat-autocomplete autoActiveFirstOption="true" hideSingleSelectionIndicator="true" #autoTimeZone="matAutocomplete" [displayWith]="displayTimeZoneName" (optionSelected)="updateSession()">
                <mat-option *ngFor="let timeZone of filteredTimeZones | async" [value]="timeZone">
                  {{ timeZone.timeZoneName }}
                </mat-option>
              </mat-autocomplete>
             
              <mat-error *ngIf="formControls.timeZone?.hasError('required') || formControls.timeZone?.hasError('invalidValue')">
                {{t('editSession.timeZoneRequired')}}
              </mat-error>
              <mat-error *ngIf="getControl('timeZone')?.errors?.['invalidAutocompleteObject']">{{ t('sessiondetails.error.time_zone_invalid') }}</mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{t('editSession.date')}}</mat-label>
              <input matInput
                     preventBlacklistCharacters
                     autocomplete="off"
                     [matDatepicker]="basicPicker"
                     [min]="minDate"
                     [max]="maxDate"
                     placeholder="{{t('editSession.dateFormatPlaceHolder')}}"
                     formControlName="date"
                     (dateChange)="setDateString($event.value)"
                     required>
              <mat-datepicker-toggle matSuffix [for]="basicPicker">
                <fa-icon [icon]="icons.faCalendar" matDatepickerToggleIcon></fa-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #basicPicker></mat-datepicker>
              <mat-hint align="end">{{t('editSession.dateFormat')}}</mat-hint>
              <mat-error *ngIf="formControls.date?.hasError('required') && !formControls.date?.hasError('matDatepickerParse')">
                {{t('editSession.requiredDate')}}
              </mat-error>
              <mat-error *ngIf="(formControls.date?.hasError('matDatepickerParse') && formControls.date?.hasError('invalidDateFormat'))">
                {{ t('editSession.validDateFormat') }}
              </mat-error>
              <mat-error *ngIf="formControls.date?.hasError('isPastDate')">
                {{ t('editSession.isPastDate') }}
              </mat-error>
              <mat-error *ngIf="formControls.date?.hasError('isFutureDate')">
                {{ t('editSession.isFutureDate') }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="grid-col-span-2">
            <topaz-timepicker [label]="timePickerLabel"
                              [interval]="5"
                              [is24hrFormat]="is24hrFormat"
                              formControlName="timePicker"
                              [required]="true"
                              (timeSelected)="setTimeString($event)"
                              [selectedTime]="selectedTime"
                              (click)="onTimepickerClick()"
                              (input)="onTimepickerInput()"
                              #timePicker></topaz-timepicker>

            <mat-error class="timpicker-error" *ngIf="this.formControls.timePicker?.touched && (this.formControls.timePicker?.status === 'INVALID' || this.formControls.timePicker?.hasError('required'))">
              {{ t('editSession.timeRequired') }}
            </mat-error>

            <mat-radio-group class="topaz-radio-group-row" (change)="is24hrFormat = !is24hrFormat">
              <mat-radio-button [value]="12" [checked]="!is24hrFormat">
                {{t('editSession.12HourFormat')}}
              </mat-radio-button>

              <mat-radio-button [value]="24">
                {{t('editSession.24HourFormat')}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </form>
    </div>

    <div class="session-exams">
      <app-edit-session-exams [session]="session" [examCount]="this.examCount" (onEditExamSessionsValidated)="save()" [isUserAssignedPayment]="isUserAssignedPayment">
      </app-edit-session-exams>
    </div>
  </div>
  <div side-sheet-footer>
    <button mat-button (click)="closeSideSheet()" class="mr-1">{{t('editSession.cancel')}}</button>
    <button mat-flat-button color="primary" (click)="validateForm()">{{t('editSession.save')}}</button>
  </div>

</topaz-side-sheet-content>
</ng-container>
