import { Injectable } from '@angular/core';

export enum SessionInvokeType {
  ViewSession = "View Session",
  EditSession = "Edit Session",
  StartSession = "Start Session",
  ResendConfirmationEmail = "Resend confirmation email"
}
export interface InvokeSessionDetails {
  invokeType: SessionInvokeType,
  sessionId: number,
  sessionCode: string,
  invokeSessionData: boolean
}

@Injectable({
  providedIn: 'root'
})
export class SharedStateService {

  private invokeSmsSignupPopup: boolean = false;
  private invokeSessionData!: InvokeSessionDetails;

  constructor() { }

  setInvokeSmsSignupPopup(value: boolean): void {
    this.invokeSmsSignupPopup = value;
  }

  getInvokeSmsSignupPopup(): boolean {
    return this.invokeSmsSignupPopup;
  }

  setInvokeSessionData(invokeSessionDetails: InvokeSessionDetails): void {
    this.invokeSessionData = invokeSessionDetails;
  }

  getInvokeSessionData(): InvokeSessionDetails {
    return this.invokeSessionData;
  }

  clearSessionData() {
    this.invokeSessionData = {} as InvokeSessionDetails;
  }
}
