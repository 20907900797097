import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceTypeService {

  isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  isMobileDeviceWidth() {
    return (window.innerWidth <= 600);
  }
}
