import { Injectable, OnDestroy } from '@angular/core';
import { EMPTY, Subject, catchError, distinctUntilChanged, takeUntil } from 'rxjs';
import { SchedulingErrorCodeService } from 'src/app/services/scheduling-error-code.service';
import { SettingsCacheService } from 'src/app/services/settings-cache.service';
import { CommunicationPreferenceRequestService} from '@certiport/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SmsSignUpDialogComponent } from '../sms-sign-up-dialog/sms-sign-up-dialog.component';
import { SharedStateService } from 'src/app/services/shared-state.service';
import { Router } from '@angular/router';
import { LoaderService } from 'certiport-layout-library';

@Injectable({
  providedIn: 'root'
})
export class SmsFeatureService implements OnDestroy {

  private destroy$ = new Subject<void>();
  smsFeatureEnabled: boolean = false;
  isUserOptedSmsFeature: boolean = false;
  private dialogOpen = false;

  constructor(private settingsCache: SettingsCacheService,
    private schedulingErrorCodeService: SchedulingErrorCodeService,
    private communicationService: CommunicationPreferenceRequestService,
    private dialog: MatDialog,
    private sharedStateService: SharedStateService,
    private router: Router,
    private loaderService: LoaderService) {
  }

  CheckIsUserOptedSmsFeature() {
    if (this.settingsCache.smsFeatureEnabled) {
      this.subscribeToPreferences();
      this.subscribeToErrors();
      this.communicationService.getPreferencesEx();
    }
  }

  private subscribeToPreferences() {
    this.communicationService.preferencesObservable
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(communicationPreferences => {
        if (communicationPreferences &&
          !communicationPreferences.compassCloudTextoptIn &&
          !communicationPreferences.dontAskMeSmsPreferences && !this.dialogOpen) {
          this.ShowSmsDialog();
        }
      });
  }

  private subscribeToErrors() {
    this.communicationService.errorObservable
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error?.errorCode)
            this.schedulingErrorCodeService.showErrorCodeToast(error?.errorCode, error?.traceId);
          else
            this.schedulingErrorCodeService.showGenericErrorToast();
          return EMPTY;
        })
      )
      .subscribe();
  }

  private dialogRef: MatDialogRef<SmsSignUpDialogComponent, any> | null = null;

  ShowSmsDialog() {
    if (this.dialogOpen) return;

    this.dialogOpen = true;
    this.dialogRef?.close();
    this.dialogRef = this.dialog.open(SmsSignUpDialogComponent, { disableClose: true, maxWidth: '750px' });

    this.dialogRef.afterClosed().subscribe({
      next: (result) => {
        this.sharedStateService.setInvokeSmsSignupPopup(false);
        if (result.isDontShowThisAgainChecked) {
          this.updateCommunicationPreferences(result);
        } else if (result.actionType === 'Signup') {
          this.navigateToPreferences();
        }
      }
    });
  }

  private navigateToPreferences() {
    this.router.navigate(['/preferences']);
  }

  updateCommunicationPreferences(data: any) {
    this.loaderService.showLoader();
    this.communicationService.updateCommunicationPreferences({ dontAskMeSmsPreferences: true }).subscribe({
      next: () => {
        this.loaderService.hideLoader();
        if (data.actionType == 'Signup') {
          this.navigateToPreferences();
        }
      },
      error: (error) => {
        this.loaderService.hideLoader();
        if (error?.errorCode)
          this.schedulingErrorCodeService.showErrorCodeToast(error?.errorCode, error?.traceId);
        else
          this.schedulingErrorCodeService.showGenericErrorToast();
      }
    })
  }

  resetDialogRef() {
    this.dialogOpen = false;
    this.dialogRef = null;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}